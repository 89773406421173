import React from 'react'
import { navigate } from 'gatsby'

import { useAccount } from 'account/context/AccountContext'

interface Props {
  component: React.ReactNode
  path: string
}

const PrivateRoute = ({ component, path }: Props) => {
  const { state } = useAccount()
  if (
    !state.awsAuthenticated &&
    path !== '/account/sign-in' &&
    typeof window !== 'undefined'
  ) {
    // If we’re not logged in, redirect to the home page.
    navigate('/account/sign-in')
    return null
  }

  return <>{component}</>
}

export default PrivateRoute
