// npm
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Grid, Skeleton } from '@mui/material'
//core
import Pagination from '_core/components/molecules/pagination/Pagination'
import MasonryGrid from '_core/components/organisms/masonry/MasonryGrid'
import { dynamicBlogQuery } from '_core/constants/dynamic-data-queries'
import useDynamicData from '_core/helpers/useDynamicData'
//account
import Layout from 'account/components/organisms/global/Layout'
//public
import BlogPostCard from 'public/components/molecules/cards/blogPost/BlogPostCard'
import SectionHeading from 'public/components/molecules/sections/SectionHeading'

const MarketUpdates = () => {
  const data = useStaticQuery<Queries.MarketUpdatesQuery>(graphql`
    query MarketUpdates {
      datoCmsAppAccountPage(originalId: { eq: "32377953" }) {
        ...accountPage
      }
    }
  `)
  const d = data.datoCmsAppAccountPage

  const { contentProps, isDynamic, paginationProps } = useDynamicData({
    perPage: 12,
    dynamicType: 'post',
    dataResultSelector: 'posts',
    dynamicContentQuery: dynamicBlogQuery,
    customFilter: { blogType: 'market-update' },
  })

  const gridContainerProps = {
    container: true,
    spacing: 2,
    sx: { alignItems: 'stretch', justifyContent: 'flex-start' },
  }
  const gridItemProps = {
    item: true,
    xs: 12,
    sm: 6,
    lg: 4,
  }

  return (
    <Layout
      slug={d?.slug}
      metadata={{
        seoTags: d?.seoMetaTags,
      }}
    >
      <SectionHeading
        heading={d?.title}
        boxProps={{
          mt: 8,
        }}
      />

      {!contentProps.fetched ? (
        <MasonryGrid masonryLayout="narrow" className="mandate-landing-skeleton">
          {Array.from(Array(12)).map((_, index: number) => {
            return (
              <Skeleton
                key={`skeleton-${index}`}
                variant="rectangular"
                height={400}
                animation="wave"
                sx={{ mb: 2 }}
              />
            )
          })}
        </MasonryGrid>
      ) : (
        <>
          {isDynamic && contentProps && contentProps.items && contentProps.items.length > 0 && (
            <>
              <Grid {...gridContainerProps}>
                {contentProps.items.map((item: any) => {
                  const post: Queries.DatoCmsBlogPost = item.node
                  return (
                    <Grid
                      key={`dynamic-b-${post.id ? post.id : post.originalId}`}
                      {...gridItemProps}
                    >
                      <BlogPostCard post={post} isDynamic={isDynamic} />
                    </Grid>
                  )
                })}
              </Grid>

              <Pagination landingPathPrefix="mandates" {...paginationProps} />
            </>
          )}
        </>
      )}
    </Layout>
  )
}

export default MarketUpdates
