import React from 'react'
import { RouteComponentProps } from '@reach/router'

import Flex from '_core/components/atoms/layout/Flex'
import Section from '_core/components/atoms/layout/Section'
import { CustomSeoTagsProps } from '_core/helpers/metadata'

import Footer from 'account/components/organisms/global/Footer'
import Layout from 'public/components/organisms/global/Layout'

interface Props extends RouteComponentProps {
  metaTags: CustomSeoTagsProps
  children: React.ReactNode
}

const AuthPageLayout = ({ metaTags, children, ...props }: Props) => {
  return (
    <Layout forceSolidNav noFooter metadata={{ seoTags: metaTags }}>
      <Section bgcolor="lightGrey" style={{ minHeight: '100vh' }}>
        <Flex flexDirection="column" fullWidth style={{ minHeight: '100vh' }}>
          {children}
          <Footer />
        </Flex>
      </Section>
    </Layout>
  )
}

export default AuthPageLayout
