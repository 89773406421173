import React, { memo } from 'react'
import { Box, BoxProps, styled } from '@mui/material'
import TimeAgoBase from 'react-timeago'

interface Props extends BoxProps {
  date: Date
}

const Date = styled(TimeAgoBase)(({ theme }) => ({
  textTransform: 'uppercase',
  fontSize: '1.2rem',
  fontWeight: theme.typography.fontWeightSemiBold,
}))

const TimeAgo = ({ date, ...props }: Props) => <Date date={date} {...props} />

export default memo(TimeAgo)
