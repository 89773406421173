import React from 'react'
import {
  Slider as SliderCore,
  SliderProps as SliderCoreProps,
  styled,
} from '@mui/material'

export interface SliderProps extends SliderCoreProps {
  error?: boolean
}

const StyledSlider = styled(SliderCore)(({ theme }) => ({
  height: '10px',
  ['.MuiSlider-rail']: {
    color: 'white',
    height: '8px',
    borderRadius: '4px',
    opacity: 1,
    border: '1px solid #dee2e3',
    transition: theme.transitions.create(['border-color']),
  },
  ['.MuiSlider-track']: {
    color: 'transparent',
    background: 'linear-gradient(to right, #09bce8, #35cabf)',
    height: '8px',
    borderRadius: theme?.shape?.borderRadiusSmall,
    marginTop: '1px',
  },
  ['.MuiSlider-thumb']: {
    top: '50%',
    width: '22px',
    height: '22px',
    color: '#d5d6dc',
    marginTop: 0,
    marginLeft: '1px',
    border: '7px solid #fff',
    transform: 'translate3d(-50%, -50%, 0)',
    ['&:before']: {
      content: '""',
      background: 'none',
      width: '22px',
      height: '22px',
      display: 'block',
      position: 'absolute',
      borderRadius: '50%',
      boxShadow: '0 2px 6px 3px rgba(0, 0, 0, 0.1)',
      border: '1px solid rgba(255, 255, 255, 0)',
      transition: theme.transitions.create(['border-color']),
    },
    ['.MuiSlider-valueLabel']: {
      position: 'absolute',
      top: '-14px',
      color: 'white',
      background: 'none',
      ['> span']: {
        width: '32px',
        height: '32px',
        display: 'flex',
        transform: 'rotate(-45deg)',
        alignItems: 'center',
        borderRadius: '50% 50% 50% 0',
        justifyContent: 'center',
        border: '1px solid #dbdce1',
        boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.1)',
        backgroundColor: 'white',
        transition: theme.transitions.create(['border-color']),
        ['> span']: {
          fontSize: '1.3rem',
          transform: 'rotate(45deg)',
          color: 'black',
          fontWeight: theme.typography.fontWeightSemiBold,
          transition: theme.transitions.create(['color']),
        },
      },
    },
  },
  ['.MuiSlider-mark']: {
    color: '#d9d9d9',
    height: '10px',
    transform: 'translateY(20px)',
    ['&.MuiSlider-markActive']: {
      opacity: 1,
      backgroundColor: '#d9d9d9',
    },
  },
  ['.MuiSlider-markLabel']: {
    fontSize: '1.5rem',
    transform: 'translate3d(-50%, 24px, 0)',
  },
  ['.MuiSlider-markLabel, .MuiSlider-markLabelActive']: {
    color: theme.palette.text.primary,
  },
}))

const Slider = ({ error, ...props }: SliderProps) => {
  return <StyledSlider {...props} className={error ? 'error' : ''} />
}

export default Slider
