// npm
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Box, Divider } from '@mui/material'

// core
import FileButton from '_core/components/atoms/buttons/FileButton'
import FormikFileUpload from '_core/components/atoms/inputs/formik/FormikFileUpload'
import FormikLinkedIn from '_core/components/atoms/inputs/formik/FormikLinkedIn'
import FormikPhoneNumber from '_core/components/atoms/inputs/formik/FormikPhoneNumber'
import FormikTextField from '_core/components/atoms/inputs/formik/FormikTextField'
import ExternalLink from '_core/components/atoms/links/ExternalLink'
import LanguageButtonGroup from '_core/components/molecules/buttonGroups/LanguageButtonGroup'
import TermButtonQueryGroup from '_core/components/molecules/buttonGroups/TermButtonQueryGroup'
import Panel from '_core/components/molecules/panels/Panel'
import PanelHeader from '_core/components/molecules/panels/PanelHeader'

// account
import EditRowWrapper from 'account/components/molecules/editRow/EditRowWrapper'
import Experience from 'account/components/organisms/employmentProfile/steps/Experience'
import Languages from 'account/components/organisms/employmentProfile/steps/Languages'
import TermButtonsInput from 'account/components/organisms/employmentProfile/steps/TermButtons'
import { useAccount } from 'account/context/AccountContext'
import { language } from '@account/context/accountReducer'
import { validation } from 'account/helpers/profileFields'

interface Props {
  validation: validation
}

const ProfileForm = ({ validation }: Props) => {
  const { state } = useAccount()

  const data = useStaticQuery<Queries.ProfileFormQuery>(graphql`
    query ProfileForm {
      datoCmsAppEmploymentProfile {
        formGroups {
          heading
          slug
          originalId
          profileFields {
            ... on DatoCmsAppProfileField {
              name
              originalId
              fieldId
              model {
                apiKey
              }
            }
            ... on DatoCmsAppProfileFormEditGroup {
              groupType
              name
              profileFields {
                originalId
                name
                fieldId
              }
              model {
                apiKey
              }
            }
          }
        }
      }
    }
  `)
  const d = data.datoCmsAppEmploymentProfile
  const panelHeaderProps = { py: { xs: 3, md: 4 } }

  return (
    <>
      {state.profile && (
        <>
          {d?.formGroups?.map((group: Queries.DatoCmsAppProfileFormGroup) => {
            return (
              <Panel key={group.originalId}>
                <>
                  <PanelHeader
                    heading={group.heading}
                    {...panelHeaderProps}
                    useSmallHeading
                  />
                  {group.profileFields?.map((field: any, index: number) => {
                    if (field?.model?.apiKey === 'profile_field') {
                      const profileField: Queries.DatoCmsAppProfileField = field
                      switch (profileField.fieldId) {
                        case 'yearsOfExperience':
                          /* ---------------------------------- */
                          /* ----------- Experience ----------- */
                          /* ---------------------------------- */
                          return (
                            <EditRowWrapper
                              type="profile"
                              key={`edit-profile-row-${profileField.fieldId}-${index}`}
                              drawerHeading={profileField.name}
                              fields={[
                                {
                                  name: profileField.name,
                                  mobileInline: true,
                                  fieldId: profileField.fieldId,
                                  validation: validation[profileField.fieldId],
                                  value: state?.profile[profileField.fieldId],
                                  displayComponent: (
                                    <Box fontWeight="semiBold">
                                      {state?.profile[profileField.fieldId]}
                                    </Box>
                                  ),
                                  drawerComponent: (
                                    <Experience
                                      fieldName={profileField.fieldId}
                                    />
                                  ),
                                },
                              ]}
                            />
                          )
                          break
                        case 'departments':
                        case 'regions':
                        case 'levels':
                        case 'locations':
                        case 'products':
                        case 'sectors':
                          return (
                            /* ---------------------------------- */
                            /* -------------- Terms ------------- */
                            /* ---------------------------------- */
                            <EditRowWrapper
                              key={`edit-profile-row-${profileField.fieldId}-${index}`}
                              type="profile"
                              drawerHeading={profileField.name}
                              fields={[
                                {
                                  name: profileField.name,
                                  fieldId: profileField.fieldId,
                                  validation: validation[profileField.fieldId],
                                  value: state?.profile[profileField.fieldId],
                                  buttonLayout: true,
                                  isTerms: true,
                                  isEmpty:
                                    state?.profile[profileField.fieldId] ===
                                      null ||
                                    state?.profile[profileField.fieldId]
                                      .length === 0
                                      ? true
                                      : false,
                                  displayComponent: (
                                    <TermButtonQueryGroup
                                      keyId={profileField.fieldId}
                                      category={profileField.fieldId}
                                      filterTerms={
                                        state?.profile[profileField.fieldId]
                                      }
                                      termButtonProps={{
                                        size: 'xl',
                                      }}
                                    />
                                  ),
                                  drawerComponent: (
                                    <TermButtonsInput
                                      termCategory={profileField.fieldId}
                                    />
                                  ),
                                },
                              ]}
                            />
                          )
                          break
                        case 'languages':
                          const languages = new Array()
                          state.profile.languages.map((language: language) => {
                            languages.push({
                              language: {
                                name: language.language,
                              },
                              proficiency: {
                                ...language.proficiency,
                              },
                              buttonProps: {
                                size: 'xl',
                                disabled: true,
                                disabledNormalText: true,
                              },
                            })
                          })

                          return (
                            /* ---------------------------------- */
                            /* ------------ Languages ----------- */
                            /* ---------------------------------- */
                            <EditRowWrapper
                              key={`edit-profile-row-${profileField.fieldId}-${index}`}
                              type="profile"
                              drawerHeading={profileField.name}
                              fields={[
                                {
                                  name: profileField.name,
                                  isLanguages: true,
                                  buttonLayout: true,
                                  fieldId: profileField.fieldId,
                                  validation: validation[profileField.fieldId],
                                  value: state?.profile.languages,
                                  isEmpty:
                                    state?.profile.languages.length === 0
                                      ? true
                                      : false,
                                  displayComponent: (
                                    <LanguageButtonGroup
                                      keyId={profileField.fieldId}
                                      languages={languages}
                                    />
                                  ),
                                  drawerComponent: (
                                    <Languages
                                      fieldName={profileField.fieldId}
                                    />
                                  ),
                                },
                              ]}
                            />
                          )
                          break
                        case 'mobileNumber':
                          return (
                            /* ---------------------------------- */
                            /* ---------- Mobile Number --------- */
                            /* ---------------------------------- */
                            <EditRowWrapper
                              key={`edit-profile-row-${profileField.fieldId}-${index}`}
                              type="profile"
                              drawerHeading={profileField.name}
                              fields={[
                                {
                                  parent: 'contactInformation',
                                  updateWithParent: true,
                                  name: profileField.name,
                                  fieldId: profileField.fieldId,
                                  mobileInline: true,
                                  validation: validation.mobileNumber,
                                  value:
                                    state.profile.contactInformation
                                      .mobileNumber,
                                  displayComponent: (
                                    <Box fontWeight="semiBold">
                                      {
                                        state.profile?.contactInformation
                                          .mobileNumber
                                      }
                                    </Box>
                                  ),
                                  drawerComponent: (
                                    <FormikPhoneNumber
                                      name={profileField.fieldId}
                                    />
                                  ),
                                },
                              ]}
                            />
                          )
                          break
                      }
                    }

                    if (field.model.apiKey === 'profile_form_edit_group') {
                      // @ts-ignore
                      const editGroup: Queries.DatoCmsAppProfileFormEditGroup =
                        field
                      switch (editGroup.groupType) {
                        /* ---------------------------------- */
                        /* -------- Employment Group -------- */
                        /* ---------------------------------- */
                        case 'currentEmployer':
                          const employerField =
                            editGroup?.profileFields?.filter(
                              (field: Queries.DatoCmsAppProfileField) =>
                                field.fieldId === 'employer'
                            )[0]

                          const jobTitleField =
                            editGroup?.profileFields?.filter(
                              (field: Queries.DatoCmsAppProfileField) =>
                                field.fieldId === 'jobTitle'
                            )[0]

                          return (
                            <EditRowWrapper
                              key={`edit-profile-row-${editGroup.groupType}-${index}`}
                              type="profile"
                              drawerHeading={editGroup.name}
                              fields={[
                                {
                                  parent: editGroup.groupType,
                                  updateWithParent: true,
                                  name: employerField?.name,
                                  fieldId: employerField?.fieldId,
                                  value:
                                    state.profile?.currentEmployer.employer,
                                  validation: validation[employerField.fieldId],
                                  mobileInline: true,
                                  displayComponent: (
                                    <Box fontWeight="semiBold">
                                      {state.profile?.currentEmployer.employer}
                                    </Box>
                                  ),
                                  drawerComponent: (
                                    <FormikTextField
                                      name={employerField.fieldId}
                                      label={employerField.name}
                                      placeholder={employerField.name}
                                      grey
                                      fullWidth
                                    />
                                  ),
                                },
                                {
                                  parent: editGroup.groupType,
                                  updateWithParent: true,
                                  name: jobTitleField.name,
                                  fieldId: jobTitleField.fieldId,
                                  value:
                                    state.profile?.currentEmployer.jobTitle,
                                  validation: validation[jobTitleField.fieldId],
                                  mobileInline: true,
                                  displayComponent: (
                                    <Box fontWeight="semiBold">
                                      {state.profile?.currentEmployer.jobTitle}
                                    </Box>
                                  ),
                                  drawerComponent: (
                                    <FormikTextField
                                      name={jobTitleField.fieldId}
                                      label={jobTitleField.name}
                                      placeholder={jobTitleField.name}
                                      grey
                                      fullWidth
                                    />
                                  ),
                                },
                              ]}
                            />
                          )
                          break

                        case 'history':
                          /* ---------------------------------- */
                          /* ---------- History Group --------- */
                          /* ---------------------------------- */
                          const cvField = editGroup?.profileFields?.filter(
                            (field: Queries.DatoCmsAppProfileField) =>
                              field.fieldId === 'cv'
                          )[0]

                          const linkedInField =
                            editGroup?.profileFields?.filter(
                              (field: Queries.DatoCmsAppProfileField) =>
                                field.fieldId === 'linkedIn'
                            )[0]

                          return (
                            <EditRowWrapper
                              key={`edit-profile-row-${editGroup.groupType}-${index}`}
                              type="profile"
                              drawerHeading={editGroup.name}
                              fields={[
                                {
                                  parent: editGroup.groupType,
                                  name: cvField.name,
                                  isCv: true,
                                  buttonLayout: true,
                                  fieldId: cvField.fieldId,
                                  value: state.profile.cv,
                                  validation: validation[cvField.fieldId],
                                  mobileInline: true,
                                  displayComponent: (
                                    <FileButton
                                      sizeVariant="xl"
                                      colorVariant="outline-disabled"
                                      termButtonXl
                                      filename={state.profile.cv.replace(
                                        /^.*[\\\/]/,
                                        ''
                                      )}
                                    />
                                  ),
                                  drawerComponent: (
                                    <>
                                      <FormikFileUpload
                                        name={cvField.fieldId}
                                        id="cvUpload"
                                        accept=".pdf, .doc, .docx"
                                        label="Replace CV"
                                        helperText="Your CV must be a either pdf or word file."
                                      />
                                      <Divider sx={{ my: 4 }} />
                                    </>
                                  ),
                                },
                                {
                                  parent: editGroup.groupType,
                                  name: linkedInField.name,
                                  fieldId: linkedInField.fieldId,
                                  value:
                                    state.profile && state.profile.linkedIn
                                      ? state.profile.linkedIn
                                      : '',
                                  isEmpty:
                                    state.profile && !state.profile.linkedIn,
                                  buttonLayout:
                                    state.profile && !state.profile.linkedIn,
                                  validation: validation[linkedInField.fieldId],
                                  mobileInline: true,
                                  displayComponent: (
                                    <>
                                      {state.profile &&
                                        state.profile.linkedIn && (
                                          <Box fontWeight="semiBold">
                                            <ExternalLink
                                              colorVariant="primary"
                                              href={`https://www.linkedin.com/${state.profile.linkedIn}`}
                                            >
                                              www.linkedin.com/
                                              {state.profile.linkedIn}
                                            </ExternalLink>
                                          </Box>
                                        )}
                                    </>
                                  ),
                                  drawerComponent: <FormikLinkedIn />,
                                },
                              ]}
                            />
                          )
                          break
                      }
                    }
                  })}
                </>
              </Panel>
            )
          })}
        </>
      )}
    </>
  )
}

export default ProfileForm
