import React, { memo } from 'react'
import { Field, FieldProps, useFormikContext } from 'formik'

import PhoneNumber, { PhoneNumberProps } from '../PhoneNumber'
import ErrorMessage from '_core/components/atoms/inputs/ErrorMessage'

export interface Props extends PhoneNumberProps {
  name: string
  omitError?: boolean
}

const FormikPhoneNumber = ({ name, omitError, ...props }: Props) => {
  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => {
        const hasError = meta.touched === true && meta.error !== undefined
        const { name, value, onChange, onBlur } = field

        return (
          <>
            {!omitError && (
              <ErrorMessage collapsed={hasError} mb={1}>
                {meta.error}
              </ErrorMessage>
            )}

            <PhoneNumber
              wrapperProps={{ className: hasError ? 'error' : '' }}
              value={value}
              inputProps={{
                name,
                onChange,
                onBlur,
              }}
              autoFormat={false}
              {...props}
            />
          </>
        )
      }}
    </Field>
  )
}

export default memo(FormikPhoneNumber)
