import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import MandateLanding from './templates/MandateLanding'

const MandateApplications = () => {
  const data = useStaticQuery<Queries.MandateApplicationsQuery>(graphql`
    query MandateApplications {
      datoCmsAppAccountPage(originalId: { eq: "32377949" }) {
        ...accountPage
        requiredMessage
        requiredButton {
          ...appButton
        }
      }
    }
  `)

  return <MandateLanding data={data?.datoCmsAppAccountPage} stateType="applications" />
}

export default MandateApplications
