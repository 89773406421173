import React, { memo, useState, useEffect } from 'react'

import {
  Alert as AlertBase,
  Box,
  BoxProps,
  Collapse,
  IconButton,
  lighten,
  styled,
} from '@mui/material'
import { CheckCircle, Close } from '@mui/icons-material'

type Props = {
  severity: 'error' | 'success' | 'notice'
  children: React.ReactNode
  boxProps: BoxProps
}

type alertProps = {
  icon?: React.ReactNode
}

const StyledAlert = styled(AlertBase)(({ theme }) => ({
  ...theme.typography.smallText,
  display: 'flex',
  textAlign: 'left',
  lineHeight: 1.3,
  ['&.MuiAlert-standardSuccess']: {
    color: theme.palette.success.main,
    backgroundColor: theme.palette.success.light,
  },
  ['&.MuiAlert-standardError']: {
    color: theme.palette.error.main,
    backgroundColor: theme.palette.error.light,
  },
  ['&.MuiAlert-standardInfo']: {
    color: theme.palette.info.main,
    backgroundColor: lighten(theme.palette.info.light, 0.8),
  },
}))

const Alert = ({ severity, children, boxProps }: Props) => {
  const [open, setOpen] = useState(false)

  let alertProps: alertProps = {}
  if (severity === 'success') {
    alertProps.icon = <CheckCircle fontSize="inherit" />
  }

  useEffect(() => {
    if (children) {
      setOpen(true)
    }
  }, [])

  return (
    <Collapse in={open}>
      <Box {...boxProps}>
        <StyledAlert
          {...alertProps}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false)
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          }
        >
          {children}
        </StyledAlert>
      </Box>
    </Collapse>
  )
}

export default memo(Alert)
