import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Box, Divider, FormControl, FormGroup } from '@mui/material'
import { Assignment } from '@mui/icons-material'

import InternalButtonLink from '_core/components/atoms/buttons/InternalButtonLink'
import DataRow, {
  DataRowProps,
} from '_core/components/molecules/panels/DataRow'
import Panel from '_core/components/molecules/panels/Panel'
import PanelFooter from '_core/components/molecules/panels/PanelFooter'
import PanelFooterButtons from '_core/components/molecules/panels/PanelFooterButtons'
import TermButtonQueryGroup from '_core/components/molecules/buttonGroups/TermButtonQueryGroup'
import LanguageButtonGroup from '_core/components/molecules/buttonGroups/LanguageButtonGroup'
import termStructure, {
  termProps,
  allTerms,
} from '_core/constants/term-structure'

import ActionRequired from 'account/components/molecules/messaging/ActionRequired'
import { useAccount } from 'account/context/AccountContext'
import { language } from '@account/context/accountReducer'
import TwoColPanelLayout from '_core/components/molecules/panels/TwoColPanelLayout'
import FileButton from '_core/components/atoms/buttons/FileButton'
import ExternalLink from '_core/components/atoms/links/ExternalLink'

interface TermDataRowProps extends Omit<DataRowProps, 'children' | 'label'> {
  termKey: string
  label: string
}

const TermDataRow = ({ label, termKey, ...props }: TermDataRowProps) => {
  const { state: accountState } = useAccount()
  const profileTermCategory =
    accountState?.profile[termKey as keyof typeof accountState.profile]
  return (
    <>
      {profileTermCategory &&
        Array.isArray(profileTermCategory) &&
        profileTermCategory.length > 0 && (
          <DataRow label={`${label}:`} {...props}>
            <TermButtonQueryGroup
              keyId={termKey}
              // @ts-ignore
              category={termKey}
              filterTerms={profileTermCategory}
            />
          </DataRow>
        )}
    </>
  )
}

const ProfilePanel = () => {
  const [loaded, setLoaded] = useState(false)
  const { state: accountState, hasInterests } = useAccount()

  const data = useStaticQuery<Queries.ProfilePanelQuery>(graphql`
    query ProfilePanel {
      datoCmsAppEmploymentProfile {
        formGroups {
          heading
          slug
          originalId
          profileFields {
            ... on DatoCmsAppProfileField {
              name
              shortName
              originalId
              fieldId
              model {
                apiKey
              }
            }
            ... on DatoCmsAppProfileFormEditGroup {
              groupType
              name
              profileFields {
                originalId
                name
                shortName
                fieldId
              }
              model {
                apiKey
              }
            }
          }
        }
      }
    }
  `)
  const d = data.datoCmsAppEmploymentProfile

  const footerButtons = [
    {
      slug: 'account/employment-profile',
      label: 'Edit Employment Profile',
    },
  ]

  const headingProps = {
    m: 0,
    pt: { xs: 2 },
    pb: { xs: 1, lg: 2, xl: 3 },
    px: { xs: 2, sm: 3, lg: 4 },
    component: 'h4',
    typography: 'h4Sans',
  }

  const dataRowProps = {
    outerWrapperProps: {
      py: { xs: 1 },
      px: { xs: 2, sm: 3, lg: 4 },
    },
  }

  useEffect(() => {
    if (accountState.profile) {
      setLoaded(true)
    }
  }, [])

  return (
    <Panel
      headerProps={{
        heading: 'My Profile',
        useSmallHeading: true,
        headingIcon: <Assignment />,
      }}
    >
      {accountState.profile ? (
        <>
          <TwoColPanelLayout
            leftCol={
              <>
                {d?.formGroups?.map(
                  (group: Queries.DatoCmsAppProfileFormGroup) => {
                    if (group.slug === 'experience') {
                      return (
                        <>
                          <Box {...headingProps}>{group.heading}</Box>
                          {group.profileFields?.map(
                            (field: any, index: number) => {
                              if (field?.model?.apiKey === 'profile_field') {
                                const profileField: Queries.DatoCmsAppProfileField =
                                  field
                                switch (profileField.fieldId) {
                                  case 'yearsOfExperience':
                                    /* ---------------------------------- */
                                    /* ----------- Experience ----------- */
                                    /* ---------------------------------- */
                                    return (
                                      <DataRow
                                        key={`profile-row-${profileField.originalId}-${index}`}
                                        label={`${profileField.shortName}:`}
                                        {...dataRowProps}
                                      >
                                        {accountState.profile.yearsOfExperience}
                                      </DataRow>
                                    )
                                  case 'departments':
                                  case 'regions':
                                  case 'levels':
                                  case 'locations':
                                  case 'products':
                                  case 'sectors':
                                    /* ---------------------------------- */
                                    /* -------------- Terms ------------- */
                                    /* ---------------------------------- */
                                    return (
                                      <TermDataRow
                                        label={profileField.shortName}
                                        key={`profile-row-${profileField.originalId}-${index}`}
                                        termKey={profileField.fieldId}
                                        {...dataRowProps}
                                      />
                                    )

                                  case 'languages':
                                    /* ---------------------------------- */
                                    /* ------------ Languages ----------- */
                                    /* ---------------------------------- */
                                    const languages = new Array()
                                    accountState.profile.languages.map(
                                      (language: language, index: number) => {
                                        languages.push({
                                          language: {
                                            name: language.language,
                                            originalId: `lang-mod-${index}`,
                                          },
                                          proficiency: {
                                            ...language.proficiency,
                                          },
                                          buttonProps: {
                                            disabled: true,
                                            disabledNormalText: true,
                                          },
                                        })
                                      }
                                    )

                                    return (
                                      <DataRow
                                        label={`${profileField.shortName}:`}
                                        key={`profile-row-${profileField.originalId}-${index}`}
                                        {...dataRowProps}
                                      >
                                        <LanguageButtonGroup
                                          keyId={`profile-${profileField.originalId}`}
                                          languages={languages}
                                        />
                                      </DataRow>
                                    )
                                }
                              }

                              if (
                                field.model.apiKey === 'profile_form_edit_group'
                              ) {
                                // @ts-ignore
                                const editGroup: Queries.DatoCmsAppProfileFormEditGroup =
                                  field
                                switch (editGroup.groupType) {
                                  /* ---------------------------------- */
                                  /* -------- Employment Group -------- */
                                  /* ---------------------------------- */
                                  case 'currentEmployer':
                                    const employerField =
                                      editGroup?.profileFields?.filter(
                                        (
                                          field: Queries.DatoCmsAppProfileField
                                        ) => field.fieldId === 'employer'
                                      )[0]

                                    const jobTitleField =
                                      editGroup?.profileFields?.filter(
                                        (
                                          field: Queries.DatoCmsAppProfileField
                                        ) => field.fieldId === 'jobTitle'
                                      )[0]

                                    return <>emp</>
                                    break

                                  case 'history':
                                    /* ---------------------------------- */
                                    /* ---------- History Group --------- */
                                    /* ---------------------------------- */
                                    const cvField =
                                      editGroup?.profileFields?.filter(
                                        (
                                          field: Queries.DatoCmsAppProfileField
                                        ) => field.fieldId === 'cv'
                                      )[0]

                                    const linkedInField =
                                      editGroup?.profileFields?.filter(
                                        (
                                          field: Queries.DatoCmsAppProfileField
                                        ) => field.fieldId === 'linkedIn'
                                      )[0]

                                    return <>his</>
                                    break
                                }
                              }
                            }
                          )}
                        </>
                      )
                    }
                  }
                )}
              </>
            }
            rightCol={
              <>
                {d?.formGroups?.map(
                  (group: Queries.DatoCmsAppProfileFormGroup) => {
                    if (group.slug !== 'experience') {
                      return (
                        <>
                          <Box {...headingProps}>{group.heading}</Box>
                          {group.profileFields?.map(
                            (field: any, index: number) => {
                              if (field?.model?.apiKey === 'profile_field') {
                                const profileField: Queries.DatoCmsAppProfileField =
                                  field
                                switch (profileField.fieldId) {
                                  case 'mobileNumber':
                                    /* ---------------------------------- */
                                    /* ---------- Mobile Number --------- */
                                    /* ---------------------------------- */

                                    return (
                                      <DataRow
                                        label={`${profileField.shortName}:`}
                                        key={`profile-row-${profileField.originalId}-${index}`}
                                        {...dataRowProps}
                                      >
                                        {
                                          accountState.profile
                                            .contactInformation.mobileNumber
                                        }
                                      </DataRow>
                                    )
                                }
                              }

                              if (
                                field.model.apiKey === 'profile_form_edit_group'
                              ) {
                                // @ts-ignore
                                const editGroup: Queries.DatoCmsAppProfileFormEditGroup =
                                  field
                                switch (editGroup.groupType) {
                                  /* ---------------------------------- */
                                  /* -------- Employment Group -------- */
                                  /* ---------------------------------- */
                                  case 'currentEmployer':
                                    const employerField =
                                      editGroup?.profileFields?.filter(
                                        (
                                          field: Queries.DatoCmsAppProfileField
                                        ) => field.fieldId === 'employer'
                                      )[0]

                                    const jobTitleField =
                                      editGroup?.profileFields?.filter(
                                        (
                                          field: Queries.DatoCmsAppProfileField
                                        ) => field.fieldId === 'jobTitle'
                                      )[0]

                                    return (
                                      <Box
                                        key={`profile-row-${employerField.originalId}-${jobTitleField.originalId}-${index}`}
                                      >
                                        <DataRow
                                          label={`${employerField.shortName}:`}
                                          {...dataRowProps}
                                        >
                                          {
                                            accountState.profile
                                              ?.currentEmployer.employer
                                          }
                                        </DataRow>
                                        <DataRow
                                          label={`${jobTitleField.shortName}:`}
                                          {...dataRowProps}
                                        >
                                          {
                                            accountState.profile
                                              ?.currentEmployer.jobTitle
                                          }
                                        </DataRow>
                                      </Box>
                                    )
                                    break

                                  case 'history':
                                    /* ---------------------------------- */
                                    /* ---------- History Group --------- */
                                    /* ---------------------------------- */
                                    const cvField =
                                      editGroup?.profileFields?.filter(
                                        (
                                          field: Queries.DatoCmsAppProfileField
                                        ) => field.fieldId === 'cv'
                                      )[0]

                                    const linkedInField =
                                      editGroup?.profileFields?.filter(
                                        (
                                          field: Queries.DatoCmsAppProfileField
                                        ) => field.fieldId === 'linkedIn'
                                      )[0]

                                    return (
                                      <Box
                                        key={`profile-row-${cvField.originalId}-${linkedInField.originalId}-${index}`}
                                      >
                                        <DataRow
                                          label={`${cvField.shortName}:`}
                                          {...dataRowProps}
                                        >
                                          <FileButton
                                            sizeVariant="lg"
                                            colorVariant="outline-disabled"
                                            termButtonXl
                                            filename={accountState.profile.cv.replace(
                                              /^.*[\\\/]/,
                                              ''
                                            )}
                                          />
                                        </DataRow>
                                        <DataRow
                                          label={`${linkedInField.shortName}:`}
                                          {...dataRowProps}
                                        >
                                          <ExternalLink
                                            colorVariant="primary"
                                            href={`https://www.linkedin.com/${accountState.profile.linkedIn}`}
                                          >
                                            www.linkedin.com/
                                            {accountState.profile.linkedIn}
                                          </ExternalLink>
                                        </DataRow>
                                      </Box>
                                    )
                                    break
                                }
                              }
                            }
                          )}
                        </>
                      )
                    }
                  }
                )}
              </>
            }
          />
          <PanelFooterButtons footerButtons={footerButtons} sx={{ mt: 1 }} />
        </>
      ) : (
        <>
          <ActionRequired>
            You have not yet setup your Employment Profile
          </ActionRequired>
          <PanelFooter py={{ xs: 2 }}>
            <InternalButtonLink
              slug="account/employment-profile"
              variant="contained"
              color="primary"
              sizeVariant="lg"
              sx={{ ml: 2 }}
            >
              Setup my Employment Profile
            </InternalButtonLink>
          </PanelFooter>
        </>
      )}
    </Panel>
  )
}

export default ProfilePanel
