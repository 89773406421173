import React, { memo } from 'react'
import { styled } from '@mui/material'
import { darken } from 'polished'
import { FaCheck } from 'react-icons/fa'

import ButtonBase from '_core/components/atoms/buttons/Button'

interface ButtonCoreProps {
  checked?: true
}

export interface CheckboxButtonProps {
  name: string
  label: string
  value: string
  checked?: true
  error?: boolean
  handleClick: React.MouseEventHandler<HTMLButtonElement>
  handleChange: React.ChangeEventHandler<HTMLInputElement>
}

const Button = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== 'checked',
})<ButtonCoreProps>(({ checked, theme }) => ({
  height: '97px',
  fontSize: '1.6rem',
  whiteSpace: 'normal',
  [theme.breakpoints.up('md')]: {
    height: '107px',
    fontSize: '1.7rem',
  },
  [theme.breakpoints.up('lg')]: {
    height: '117px',
    fontSize: '1.8rem',
  },
  color: checked ? 'white' : 'inherit',
  border: '1px solid rgba(255, 255, 255, 0)',
  transition: theme.transitions.create(['border-color']),
  fontWeight: theme.typography.fontWeightSemiBold,
  letterSpacing: 0,
  lineHeight: 1.3,
  textTransform: 'none',
  backgroundColor: checked
    ? theme.palette.primary.main
    : theme.palette.buttonDefault,
  ['&:hover']: {
    backgroundColor: checked
      ? darken(0.1, theme.palette.primary.main)
      : darken(0.1, theme.palette.buttonDefault),
  },
  ['svg']: {
    position: 'absolute',
    top: '15px',
    right: '15px',
  },
}))

const HiddenCheckbox = styled('input')({
  border: 0,
  clip: 'rect(0 0 0 0)',
  clippath: 'inset(50%)',
  height: '1px',
  margin: '-1px',
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  whiteSpace: 'nowrap',
  width: '1px',
})

const CheckboxButton = ({
  name,
  label,
  value,
  checked,
  handleChange,
  handleClick,
  error,
}: Props) => {
  return (
    <>
      <HiddenCheckbox
        id={`${name}-${value}`}
        name={name}
        value={value}
        checked={checked}
        onChange={handleChange}
      />

      <Button
        fullWidth
        variant="contained"
        sizeVariant="xl"
        checked={checked}
        onClick={handleClick}
        mediumRadius
        validation={{
          error: error,
        }}
      >
        {label}
        {checked && <FaCheck />}
      </Button>
    </>
  )
}

export default memo(CheckboxButton)
