export const dynamicMandatesQuery = `
            query($filter: MandateFilter, $pagination: PaginationOptions!) {
              mandates(filter: $filter, pagination: $pagination) {
                totalCount
                edges {
                  node {
                    active
                    id
                    slug
                    title
                    minExperience
                    maxExperience
                    createdAt
                    _modelApiKey
                    departments {
                      name
                      slug
                      id
                      _modelApiKey
                      departmentColor {
                        red
                        green
                        blue
                      }                      
                    }
                    regions {
                      name
                      slug
                      id
                      _modelApiKey
                    }
                    levels {
                      name
                      slug
                      id
                      _modelApiKey
                    }
                    locations {
                      name
                      slug
                      id
                      _modelApiKey
                    }
                    products {
                      name
                      slug
                      id
                      _modelApiKey
                    }
                    sectors {
                      name
                      slug
                      id
                      _modelApiKey
                    }
                    secondaryDepartments {
                      name
                      slug
                      id
                      _modelApiKey
                    }
                    secondaryRegions {
                      name
                      slug
                      id
                      _modelApiKey
                    }
                    responsibilities {
                      name
                      slug
                      id
                      _modelApiKey
                    }
                    languages {
                      ... on LanguageRequirementRecord {
                        id
                        language {
                          name
                          slug
                        }
                        proficiency {
                          name
                        }
                      }
                    }
                    bulletPoints {
                      ... on BulletPointRecord {
                        bulletPoint
                      }
                    }
                  }
                }
              }
            }
          `

export const dynamicBlogQuery = `
    query($filter: PostFilter, $pagination: PaginationOptions!) {
      posts(filter: $filter, pagination: $pagination) {
        totalCount
        edges {
          node {
            title
            id
            publishDate
            slug
            _modelApiKey            
            blogType {
              slug
              name
            }
            authors {
              name
              id
              slug
              image {
                url
              }
            }
            image: mainImage {
              url
            }
          }
        }
      }
    }
 `
