import React from 'react'
import { Box, BoxProps, styled, useMediaQuery, useTheme } from '@mui/material'
import { Add, Edit } from '@mui/icons-material'
import { NumberSchema, StringSchema, ArraySchema, AnySchema } from 'yup'

import { termButtonStyles } from '_core/styles/term-button'
import Flex from '_core/components/atoms/layout/Flex'
import Button from '_core/components/atoms/buttons/Button'

import { initialValueType } from 'account/helpers/profileFields'

const Wrapper = styled(Box)(({ theme }) => ({
  ['&:not(:first-of-type)']: {
    borderTop: `1px solid ${theme.palette.outlineDark}`,
  },
}))

// @ts-ignore
const AddButton = styled(Button)(({ theme }) => ({
  ...termButtonStyles({ size: 'xl' }),
  ['&, &:hover']: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
}))

const ContentBox = styled(Box)(({ theme }) => ({
  ['&.full-width-btn']: {
    ['.MuiButton-root']: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        minWidth: 'auto',
      },
      minWidth: '400px',
    },
  },
}))

export type rowField = {
  isEmpty?: boolean
  isButton?: boolean
  isTerms?: boolean
  isCv?: boolean
  isLanguages?: boolean
  buttonLayout?: boolean
  parent?: string
  updateWithParent?: boolean
  mobileInline?: boolean
  name: string
  fieldId: string
  value: initialValueType
  validation: NumberSchema | StringSchema | ArraySchema<AnySchema>
  displayComponent: React.ReactNode
  drawerComponent: React.ReactNode
}

interface Props {
  fields: rowField[]
  setDrawerOpen: Function
}

const EditRow = ({ fields, setDrawerOpen }: Props) => {
  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))
  return (
    <Wrapper px={{ xs: 2, lg: 5 }} py={{ xs: 2, lg: 3 }}>
      <Flex
        fullWidth
        flexWrap={{ xs: 'wrap' }}
        flexDirection={{ xs: 'column', md: 'row' }}
      >
        <Box
          flex={1}
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: fields.length > 1 ? 'column' : 'row',
          }}
        >
          {fields.map((field: rowField, index: number) => {
            let labelProps: BoxProps = { width: { lg: '20%' } }
            let contentProps: BoxProps = {
              width: { lg: '80%' },
              flex: 1,
            }

            if (field.mobileInline) {
              labelProps = {
                ...labelProps,
                mr: {
                  xs: 1,
                  lg: '0px',
                },
              }
            } else {
              labelProps = {
                // @ts-ignore
                width: { ...labelProps.width, xs: '100%' },
              }
              contentProps = {
                ...contentProps,
                flexBasis: { xs: '100%', lg: 'auto' },
                // @ts-ignore
                width: { ...contentProps.width, xs: '100%' },
                mt: {
                  xs: 1,
                  lg: '0px',
                },
              }
            }

            if (field.isButton) {
              contentProps.className = 'full-width-btn'
            }

            return (
              <Flex
                key={`field-${field.fieldId}-${index}`}
                mt={index !== 0 ? (field.isButton ? 1 : { xs: 2 }) : '0px'}
                flexDirection={
                  field.mobileInline ? 'row' : { xs: 'column', lg: 'row' }
                }
                sx={{
                  alignSelf: field.isTerms ? 'flex-start' : 'center',
                  width: '100%',
                }}
              >
                <Box
                  {...labelProps}
                  className="label"
                  display={{
                    xs: field.isButton ? 'none' : 'block',
                    lg: 'block',
                  }}
                  sx={{
                    paddingTop: field.buttonLayout ? '21px' : 0,
                    lineHeight: field.buttonLayout ? 1 : 'inherit',
                  }}
                >
                  <>{field.name}:</>
                </Box>

                <ContentBox {...contentProps}>
                  {field.isEmpty ? (
                    <AddButton
                      sizeVariant="lg"
                      colorVariant="outline-dark"
                      variant="outlined"
                      onClick={() => setDrawerOpen(true)}
                      endIcon={<Add />}
                      fullWidth={mdUp ? false : true}
                    >
                      Add {field.name}
                    </AddButton>
                  ) : (
                    <>{field.displayComponent}</>
                  )}
                </ContentBox>
              </Flex>
            )
          })}
        </Box>
        <Button
          sizeVariant="lg"
          variant="contained"
          colorVariant="contained-light"
          aria-label="Edit"
          onClick={() => setDrawerOpen(true)}
          sx={{
            ml: { xs: 0, md: 2 },
            mt: {
              xs: 1,
              md: fields[0].buttonLayout ? '8px' : 0,
            },
          }}
          startIcon={<Edit />}
        >
          Edit
        </Button>
      </Flex>
    </Wrapper>
  )
}

export default EditRow
