import React, { memo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import TermButtonGroup, {
  TermButtonGroupProps,
} from '_core/components/molecules/buttonGroups/TermButtonGroup'

type filterTerm = {
  name: string
  slug: string
}

type term =
  | Queries.DatoCmsTermDepartment
  | Queries.DatoCmsTermRegion
  | Queries.DatoCmsTermLevel
  | Queries.DatoCmsTermLocation
  | Queries.DatoCmsTermLanguage
  | Queries.DatoCmsTermProduct
  | Queries.DatoCmsTermSector
  | Queries.DatoCmsTermResponsibility

interface Props extends Omit<TermButtonGroupProps, 'terms'> {
  category:
    | 'departments'
    | 'regions'
    | 'levels'
    | 'locations'
    | 'products'
    | 'sectors'
  filterTerms: filterTerm[]
}

const TermButtonQueryGroup = ({ filterTerms, category, ...props }: Props) => {
  const data = useStaticQuery<Queries.TermButtonQueryGroupQuery>(graphql`
    query TermButtonQueryGroup {
      allDatoCmsTermDepartment(sort: { order: ASC, fields: position }) {
        edges {
          node {
            originalId
            name
            slug
            departmentColor {
              red
              green
              blue
            }
            model {
              apiKey
            }
          }
        }
      }
      allDatoCmsTermRegion(sort: { order: ASC, fields: position }) {
        edges {
          node {
            originalId
            name
            slug
            model {
              apiKey
            }
          }
        }
      }
      allDatoCmsTermLevel(sort: { order: ASC, fields: position }) {
        edges {
          node {
            originalId
            name
            slug
            model {
              apiKey
            }
          }
        }
      }
      allDatoCmsTermLocation(sort: { order: ASC, fields: position }) {
        edges {
          node {
            originalId
            name
            slug
            model {
              apiKey
            }
          }
        }
      }
      allDatoCmsTermProduct(sort: { order: ASC, fields: position }) {
        edges {
          node {
            originalId
            name
            slug
            model {
              apiKey
            }
          }
        }
      }
      allDatoCmsTermSector(sort: { order: ASC, fields: position }) {
        edges {
          node {
            originalId
            name
            slug
            model {
              apiKey
            }
          }
        }
      }
    }
  `)

  const dataSelector = `allDatoCmsTerm${(
    category.charAt(0).toUpperCase() + category.slice(1)
  ).slice(0, -1)}`

  let filteredTerms: term[] = []
  if (filterTerms && Array.isArray(filterTerms) && filterTerms.length > 0) {
    filterTerms.map((filterTerm: filterTerm) => {
      data[dataSelector].edges.filter((item: any) => {
        const term: term = item.node
        if (term.slug === filterTerm.slug) {
          filteredTerms.push(term)
        }
      })
    })
  }

  return <TermButtonGroup terms={{ [category]: filteredTerms }} {...props} />
}

export default memo(TermButtonQueryGroup)
