import React from 'react'
import { Box, Container, Divider, Typography } from '@mui/material'
import { Formik, Form } from 'formik'
import { object, string } from 'yup'
import { useQueryParam, StringParam } from 'use-query-params'
import { navigate } from 'gatsby'
import { Auth } from '@aws-amplify/auth'
import { useAuthenticatedLocal } from 'account/context/AuthenticatedLocalContext'

import Alert from '_core/components/atoms/feedback/Alert'
import Button from '_core/components/atoms/buttons/Button'
import Flex from '_core/components/atoms/layout/Flex'
import FormikTextField from '_core/components/atoms/inputs/formik/FormikTextField'
import InternalButtonLink from '_core/components/atoms/buttons/InternalButtonLink'
import InternalLink from '_core/components/atoms/links/InternalLink'
import { useLoading } from '_core/context/LoadingContext'
import { useMessage } from '_core/context/MessageContext'
import { useAccount } from 'account/context/AccountContext'
import AWSConfig from 'account/constants/aws-config'

import AuthPageLayout from 'account/components/organisms/global/AuthPageLayout'

type FormValues = {
  email: string
  password: string
}

Auth.configure(AWSConfig)

const SignIn = () => {
  const [email, setEmail] = useQueryParam('email', StringParam)
  const { dispatch: loadingDispatch } = useLoading()
  const { dispatch: messageDispatch } = useMessage()
  const { dispatch: accountDispatch } = useAccount()
  const { setAuthenticatedLocal } = useAuthenticatedLocal()

  const initialValues = {
    email: email ? email : '',
    password: '',
  }
  const validationSchema = object().shape({
    email: string()
      .email('Please supply a valid email address')
      .required('Email address is Required'),
    password: string().required('Please Enter your password'),
  })

  const handleSubmit = async (values: FormValues) => {
    loadingDispatch({ type: 'START_LOADING' })
    try {
      await Auth.signIn(values.email, values.password).then(() => {
        loadingDispatch({ type: 'STOP_LOADING' })
        setAuthenticatedLocal()
        accountDispatch({ type: 'SET_AWSAUTHENTICATED' })
        navigate('/account/dashboard')
      })
    } catch (error: any) {
      loadingDispatch({ type: 'STOP_LOADING' })
      messageDispatch({
        type: 'SET_MESSAGE',
        payload: {
          message: error.message,
          status: 'error',
        },
      })
    }
  }

  return (
    <>
      <AuthPageLayout>
        <Flex center textAlign="center" flexGrow="1">
          <Container maxWidth="sm">
            <Box py="12">
              <Box component="h1" typography="h2" mb={1}>
                Sign in
              </Box>
              <Box component="p" mb="2" color="textGrey">
                Welcome back. Enter your details below.
              </Box>

              {email && (
                <Alert severity="success" boxProps={{ mb: { xs: 3 } }}>
                  Your email address has been verified.
                  <br />
                  You can now login.
                </Alert>
              )}

              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  handleSubmit(values)
                }}
              >
                <Form>
                  <FormikTextField
                    name="email"
                    label="Email Address"
                    placeholder="Email Address"
                    type="email"
                    // icon={envelopeIcon}
                    fullWidth
                    caps
                    required
                    sx={{ mb: 2 }}
                  />
                  <FormikTextField
                    name="password"
                    label="Password"
                    placeholder="Password"
                    type="password"
                    // icon={lockIcon}
                    fullWidth
                    caps
                    required
                    sx={{ mb: 2 }}
                  />
                  <Flex alignCenter fullWidth justifyContent="space-between">
                    <Box>
                      <InternalLink slug="account/reset-password">
                        <Typography variant="smallText">
                          Reset Your Password?
                        </Typography>
                      </InternalLink>
                    </Box>
                    <Button
                      variant="contained"
                      color="primary"
                      sizeVariant="xl"
                      type="submit"
                    >
                      Sign In
                    </Button>
                  </Flex>
                  <Divider sx={{ my: 2 }} />
                  <Box typography="smallText" color="textGrey">
                    Don't have an account?
                  </Box>
                  &nbsp;
                  <InternalButtonLink
                    slug="account/sign-up"
                    variant="outlined"
                    sizeVariant="lg"
                    colorVariant="outline-dark"
                    sx={{ ml: 1 }}
                  >
                    Sign Up
                  </InternalButtonLink>
                </Form>
              </Formik>
            </Box>
          </Container>
        </Flex>
      </AuthPageLayout>
    </>
  )
}

export default SignIn
