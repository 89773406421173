import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Box, Container } from '@mui/material'
import { Formik, Form } from 'formik'
import { boolean, object, string } from 'yup'
import { Auth } from '@aws-amplify/auth'

import Button from '_core/components/atoms/buttons/Button'
import Flex from '_core/components/atoms/layout/Flex'
import FormikTextField from '_core/components/atoms/inputs/formik/FormikTextField'
import FormikCheckbox from '_core/components/atoms/inputs/formik/FormikCheckbox'
import PasswordHelperText from '_core/components/atoms/inputs/PasswordHelperText'
import Text from 'public/components/molecules/contentComponents/Text'

import { useLoading } from '_core/context/LoadingContext'
import { useMessage } from '_core/context/MessageContext'
import AuthPageLayout from 'account/components/organisms/global/AuthPageLayout'
import AWSConfig from 'account/constants/aws-config'

type FormValues = {
  firstName: string
  lastName: string
  email: string
  password: string
  privacyPolicy: boolean
}

Auth.configure(AWSConfig)

const SignUp = () => {
  const { dispatch: loadingDispatch } = useLoading()
  const { dispatch: messageDispatch } = useMessage()

  const data = useStaticQuery<Queries.AccountSignupQuery>(graphql`
    query AccountSignup {
      datoCmsAccountSignup {
        heading
        originalId
        privacyPolicyCheckboxTextNode {
          childMarkdownRemark {
            html
          }
        }
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
      }
    }
  `)
  const page = data.datoCmsAccountSignup

  if (typeof window !== 'undefined') {
    window.gtag('event', 'account_signup_intent', {
      action: 'Sign Up Page Visited (intent to sign up)',
    })
  }

  const handleSubmit = async (values: FormValues) => {
    loadingDispatch({ type: 'START_LOADING' })
    try {
      await Auth.signUp({
        username: values.email,
        password: values.password,
        attributes: {
          'custom:firstName': values.firstName,
          'custom:lastName': values.lastName,
          'custom:privacyPolicy': 'true',
        },
      }).then(() => {
        loadingDispatch({ type: 'STOP_LOADING' })
        if (typeof window !== 'undefined') {
          window.gtag('event', 'account_signup', {
            action: 'New Account Created',
          })
        }
        messageDispatch({
          type: 'SET_MESSAGE',
          payload: {
            message:
              'Your account has been successfully created. Please check your inbox for our account verification email.',
            status: 'success',
          },
        })
      })
    } catch (error: any) {
      loadingDispatch({ type: 'STOP_LOADING' })
      messageDispatch({
        type: 'SET_MESSAGE',
        payload: {
          message: error.message,
          status: 'error',
        },
      })
    }
  }

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    privacyPolicy: false,
  }

  const validationSchema = object().shape({
    firstName: string().required('First name is Required'),
    lastName: string().required('Last name is Required'),
    email: string()
      .email('Please supply a valid email address')
      .required('Email address is Required'),
    password: string()
      .required('Please Enter your password')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/,
        'Password is not strong enough'
      ),
    privacyPolicy: boolean().oneOf(
      [true],
      'You must agree to our Privacy Policy and Terms & Conditions'
    ),
  })

  return (
    <AuthPageLayout metaTags={page?.seoMetaTags}>
      <Flex center textAlign="center" flexGrow="1" flexDirection="column">
        <Box component="h1" typography="h2" mt={12} mb={6}>
          {page?.heading}
        </Box>

        <Container maxWidth="sm">
          <Box pb="12">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <FormikTextField
                  name="firstName"
                  label="First Name"
                  placeholder="First Name"
                  fullWidth
                  caps
                  sx={{ mb: 2 }}
                />
                <FormikTextField
                  name="lastName"
                  label="Last Name"
                  placeholder="Last Name"
                  fullWidth
                  caps
                  sx={{ mb: 2 }}
                />
                <FormikTextField
                  name="email"
                  label="Email Address"
                  placeholder="Email Address"
                  type="email"
                  // icon={envelopeIcon}
                  fullWidth
                  caps
                  sx={{ mb: 2 }}
                />

                <FormikTextField
                  name="password"
                  label="Password"
                  placeholder="Password"
                  // icon={lockIcon}
                  type="password"
                  fullWidth
                  caps
                  sx={{ mb: 2 }}
                />
                <PasswordHelperText />
                <Box textAlign="left" mb={2}>
                  <FormikCheckbox
                    name="privacyPolicy"
                    smallText
                    singleCheckbox
                    labelProps={{
                      sx: {
                        display: 'inline-flex',
                        ['.MuiFormControlLabel-label']: {
                          textTransform: 'none',
                          p: {
                            marginTop: '0 !important',
                          },
                        },
                      },
                    }}
                    label={
                      <Text
                        text={
                          page.privacyPolicyCheckboxTextNode.childMarkdownRemark
                            .html
                        }
                        sx={{ marginTop: '0 !important' }}
                      />
                    }
                  />
                </Box>

                <Button
                  variant="contained"
                  color="primary"
                  sizeVariant="xl"
                  type="submit"
                >
                  Create An Account
                </Button>
              </Form>
            </Formik>
          </Box>
        </Container>
      </Flex>
    </AuthPageLayout>
  )
}

export default SignUp
