import React from 'react'

import Card from '_core/components/atoms/cards/Card'
import PanelHeader, { PanelHeaderProps } from './PanelHeader'

export interface PanelProps {
  headerProps?: PanelHeaderProps
  shortHeading?: string
  useShortHeading?: true
  children: React.ReactNode
}

const Panel = ({
  headerProps,
  shortHeading,
  useShortHeading,
  children,
  ...props
}: PanelProps) => {
  let panelHeading = undefined
  if (shortHeading && useShortHeading) {
    panelHeading = shortHeading
  } else if (headerProps && headerProps.heading) {
    panelHeading = headerProps.heading
  }

  return (
    <Card sx={{ mt: { xs: 1, md: 2 } }} disableHover {...props}>
      {panelHeading && (
        <PanelHeader {...headerProps} heading={panelHeading} withBorderBottom />
      )}

      {children}
    </Card>
  )
}

export default Panel
