import React, { memo } from 'react'
import { Box, BoxProps, Container, styled } from '@mui/material'

import Panel, { PanelProps } from '_core/components/molecules/panels/Panel'

interface Props {
  panelProps: Omit<PanelProps, 'children'>
  contentProps: BoxProps
  children: React.ReactNode
  footer?: React.ReactNode
}

const StyledPanel = styled(Panel)(({ theme }) => ({
  overflow: 'visible',
}))

const StyledContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}))

const ProfileStepPanel = ({
  panelProps,
  contentProps,
  children,
  footer,
}: Props) => {
  const panel = (
    <StyledPanel {...panelProps}>
      <Box p={{ xs: 2, md: 4, lg: 6 }} {...contentProps}>
        {children}
      </Box>
      {footer && <>{footer}</>}
    </StyledPanel>
  )

  return <StyledContainer maxWidth={false}>{panel}</StyledContainer>
}

export default memo(ProfileStepPanel)
