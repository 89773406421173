import React, { useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { SwiperSlide } from 'swiper/react'
import { Box, BoxProps, Slider, styled } from '@mui/material'
import { FaCheck } from 'react-icons/fa'

import { useAccount } from 'account/context/AccountContext'
import PageIcons from 'account/components/atoms/icons/PageIcons'
import ProgressSwiper from 'account/components/molecules/swipers/ProgressSwiper'

import Flex from '_core/components/atoms/layout/Flex'
import InternalLink from '_core/components/atoms/links/InternalLink'

interface WrapperProps {
  lightBg?: true
}
const Wrapper = styled(Flex, {
  shouldForwardProp: (prop) => prop !== 'lightBg',
})<WrapperProps>(({ lightBg, theme }) => ({
  textTransform: 'uppercase',
  letterSpacing: '0.1rem',
  fontSize: '1.1rem',
  fontWeight: theme.typography.fontWeightBold,
  verticalAlign: 'middle',
  ['.highlight']: {
    color: 'white',
  },
  ...(lightBg && {
    ['.highlight']: {
      color: theme.palette.textDark,
      ['.MuiSlider-mark']: {
        ['&, &.MuiSlider-markActive']: {
          color: 'white !important',
          backgroundColor: 'white !important',
        },
      },
    },
  }),
}))

const StyledSlider = styled(Slider)(({ theme }) => ({
  padding: '13px 0 !important',
  ['&.Mui-disabled']: {
    height: '4px',
    ['.MuiSlider-rail']: {
      color: '#828596',
      height: '4px',
      borderRadius: '2px',
      opacity: 1,
    },
    ['.MuiSlider-track']: {
      color: 'transparent',
      background: 'linear-gradient(to right, #09bce8, #35cabf)',
      height: '4px',
      border: 'none',
      transition: theme.transitions.create(['width']),
    },
    ['.MuiSlider-thumb']: {
      top: '50%',
      width: '14px',
      height: '14px',
      color: '#fffffd',
      marginTop: 0,
      marginLeft: 0,
      border: '2px solid #828596',
      transform: 'translate3d(-50%, -50%, 0)',
      transition: theme.transitions.create(['left']),
    },
    ['.MuiSlider-mark']: {
      color: theme.palette.textDark,
      height: '4px',
      ['&.MuiSlider-markActive']: {
        opacity: 1,
        backgroundColor: theme.palette.textDark,
        // ['&:nth-child(4)']: {
        //   opacity: 0,
        // },
      },
    },
  },
}))

const SliderContainer = styled(Box)(({ theme }) => ({
  height: '30px',
  position: 'relative',
  width: '100%',
}))

const SliderEndContainer = styled(Flex)(({ theme }) => ({
  width: '18px',
  height: '30px',
  position: 'absolute',
  top: 0,
  left: '100%',
  transform: 'translateX(-50%)',
}))

const SliderEnd = styled(Flex)(({ theme }) => ({
  width: '18px',
  height: '18px',
  background: '#fffffd',
  border: '2px solid #828596',
  borderRadius: '50%',
  fontSize: '10px',
  color: '#6c6f84',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

const px = {
  pl: 4,
  pr: 5,
}

interface ProgressLinkProps {
  id: string
  slug: string
  normalText: string
  highlightText: string
}
const ProgressLink = ({
  id,
  slug,
  normalText,
  highlightText,
}: ProgressLinkProps) => {
  return (
    <Box {...px}>
      <InternalLink slug={slug}>
        <Flex center>
          <Flex
            alignCenter
            sx={{ lineHeight: 1, mr: '5px', fontSize: '1.6rem' }}
          >
            <PageIcons slug={id} fontSize="inherit" />
          </Flex>
          <Box sx={{ lineHeight: 1 }}>
            {normalText} <span className="highlight">{highlightText}</span>
          </Box>
        </Flex>
      </InternalLink>
    </Box>
  )
}

interface Props {
  visibleOnComplete?: true
  wrapperProps?: BoxProps
}
const Progress = ({ visibleOnComplete, wrapperProps }: Props) => {
  const { state, dispatch } = useAccount()

  const data = useStaticQuery<Queries.ProgressQuery>(graphql`
    query Progress {
      datoCmsAppGlobal {
        profileStrengthLabel
      }
      datoCmsAppAccountProgress {
        outstandingTasks {
          startText
          slug
          originalId
          highlightedText
          pagePath
        }
        profileStrengths {
          text
        }
      }
    }
  `)
  const global = data.datoCmsAppGlobal
  const tasks = data.datoCmsAppAccountProgress.outstandingTasks
  const profileStrengths = data.datoCmsAppAccountProgress.profileStrengths

  let outstandingTasks: string[] = []

  if (state.account && !state.account.picture) {
    outstandingTasks = [...outstandingTasks, 'picture']
  }
  if (state.profile === undefined || state.profile === null) {
    outstandingTasks = [...outstandingTasks, 'profile']
  }
  if (!state.interests) {
    outstandingTasks = [...outstandingTasks, 'interests']
  }
  if (state.interests) {
    let isEmpty = true
    // terms.primary.map((term) => {
    //   if (interests[term.id]) {
    //     isEmpty = false
    //   }
    // })

    if (isEmpty) {
      outstandingTasks = [...outstandingTasks, 'interests']
    }
  }
  if (
    (state.favoriteMandates && state.favoriteMandates.length === 0) ||
    !state.favoriteMandates
  ) {
    outstandingTasks = [...outstandingTasks, 'favourite-mandates']
  }
  if (
    !state.applications ||
    (state.applications && state.applications.length === 0)
  ) {
    outstandingTasks = [...outstandingTasks, 'applications']
  }
  // setProcessTodo(true)

  useEffect(() => {
    if (outstandingTasks.length === 0) {
      dispatch({
        type: 'SET_STATE',
        payload: {
          type: 'tasksComplete',
          data: true,
        },
      })
    } else {
      dispatch({
        type: 'SET_STATE',
        payload: {
          type: 'tasksComplete',
          data: false,
        },
      })
    }
  }, [
    state.account,
    state.profile,
    state.interests,
    state.favoriteMandate,
    state.applications,
  ])

  const visible =
    visibleOnComplete || outstandingTasks.length > 0 ? true : false
  return (
    <>
      {visible && (
        <Wrapper
          flexDirection="column"
          center
          py={3}
          textAlign="center"
          color="textAccountSidebar"
          {...wrapperProps}
        >
          <Box mb={1} px={5}>
            {global.profileStrengthLabel}:{' '}
            {profileStrengths &&
              profileStrengths[tasks.length - outstandingTasks.length - 1] &&
              profileStrengths[tasks.length - outstandingTasks.length - 1]
                .text && (
                <span className="highlight">
                  {
                    profileStrengths[tasks.length - outstandingTasks.length - 1]
                      .text
                  }
                </span>
              )}
          </Box>
          <Box width="100%" {...px} mb={1}>
            <SliderContainer>
              <StyledSlider
                value={tasks.length - outstandingTasks.length - 1}
                step={1}
                min={0}
                max={tasks.length}
                disabled
                marks={true}
              />
              <SliderEndContainer alignCenter>
                <SliderEnd>
                  <FaCheck />
                </SliderEnd>
              </SliderEndContainer>
            </SliderContainer>
          </Box>

          <ProgressSwiper>
            {outstandingTasks.map((task) => {
              const taskData: Queries.DatoCmsAccountCompletionTask =
                tasks.filter(
                  (dataItem: Queries.DatoCmsAccountCompletionTask) =>
                    dataItem.slug === task
                )
              return (
                <SwiperSlide key={task}>
                  <ProgressLink
                    id={taskData[0].slug}
                    slug={taskData[0].pagePath}
                    normalText={taskData[0].startText}
                    highlightText={taskData[0].highlightedText}
                  />
                </SwiperSlide>
              )
            })}
          </ProgressSwiper>
        </Wrapper>
      )}
    </>
  )
}

export default Progress
