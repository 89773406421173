import React, { memo } from 'react'
import { InputAdornment } from '@mui/material'

import Flex, { FlexProps } from '_core/components/atoms/layout/Flex'
import FormikTextField from './FormikTextField'

const FormikLinkedIn = (props: FlexProps) => {
  return (
    <>
      <Flex fullWidth alignCenter {...props}>
        <FormikTextField
          name="linkedIn"
          label="LinkedIn Profile Url"
          placeholder="LinkedIn Profile Url"
          grey
          fullWidth
          withAdornment
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">linkedin.com/</InputAdornment>
            ),
          }}
        />
      </Flex>
    </>
  )
}

export default memo(FormikLinkedIn)
