import React from 'react'
import { Box, styled } from '@mui/material'

import Flex from '_core/components/atoms/layout/Flex'
import InternalLink from '_core/components/atoms/links/InternalLink'

interface Props {
  heading: string
  slug: string
  apiKey: string
  postContent?: React.ReactNode
}

const StyledCard = styled(Flex)(({ theme }) => ({
  borderLeft: '4px solid rgba(255, 255, 255, 0)',
  transition: theme.transitions.create(['background-color', 'border-left-color']),
  ['&:not(:last-of-type)']: {
    borderBottom: `1px solid ${theme.palette.outlineDark}`,
  },
  ['.heading']: {
    transition: theme.transitions.create(['color']),
  },
  ['&:hover']: {
    borderLeftColor: theme.palette.primary.main,
    backgroundColor: `rgba(40, 42, 60, 0.03)`,
    ['.heading']: {
      color: `${theme.palette.primary.main} !important`,
    },
  },
}))

const StyledInternalLink = styled(InternalLink)(({ theme }) => ({
  flex: 1,
}))

const PanelCard = ({ heading, slug, apiKey, postContent }: Props) => {
  return (
    <StyledCard py={2} pr={{ xs: 2, md: 4 }} pl={{ xs: '14px', md: '36px' }} alignCenter fullWidth>
      <StyledInternalLink slug={slug} apiKey={apiKey}>
        <Box
          component="h3"
          fontSize="1.7rem"
          fontWeight="fontWeightRegular"
          className="heading"
          m={0}
        >
          {heading}
        </Box>
      </StyledInternalLink>
      {postContent && (
        <Flex alignCenter ml={{ xs: 1, sm: 2, md: 3, lg: 4 }}>
          {postContent}
        </Flex>
      )}
    </StyledCard>
  )
}

export default PanelCard
