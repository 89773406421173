import React, { memo, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useFormikContext, FieldArray } from 'formik'
import { Base64 } from 'js-base64'

import DataRow from '_core/components/molecules/panels/DataRow'
import FormikTextSelectField from '_core/components/atoms/inputs/formik/FormikTextSelectField'
import Button from '_core/components/atoms/buttons/Button'
import ErrorMessage from '_core/components/atoms/inputs/ErrorMessage'

type proficiencySelectItem = {
  originalId: string
  name: string
  value: string
}
interface Props {
  fieldName: string
}

const Languages = ({ fieldName }: Props) => {
  const { getFieldMeta, values } = useFormikContext()

  const [hasError, setHasError] = useState(false)
  const [isValid, setIsValid] = useState(false)

  const data = useStaticQuery<Queries.LanguagesQuery>(graphql`
    query Languages {
      allDatoCmsLanguageProficiency {
        edges {
          node {
            originalId
            name
            slug
          }
        }
      }
    }
  `)

  const blankLanguage = { language: '', proficiency: '' }

  let items: proficiencySelectItem[] = []

  data.allDatoCmsLanguageProficiency.edges.map((term) => {
    const valueObj = {
      name: term.node.name,
      slug: term.node.slug,
    }
    const valueString = Base64.encode(JSON.stringify(valueObj))

    items.push({
      originalId: term.node.originalId,
      name: term.node.name,
      value: valueString,
    })
  })

  return (
    <FieldArray
      name={fieldName}
      render={(arrayHelpers) => (
        <>
          {values.languages.map((language, index: number) => {
            const textName = `languages[${index}].language`
            const selectName = `languages[${index}].proficiency`

            const textMeta = getFieldMeta(textName)
            const selectMeta = getFieldMeta(selectName)

            if (
              (textMeta.touched && textMeta.error) ||
              (selectMeta.touched && selectMeta.error)
            ) {
              setHasError(true)
            }
            if (
              textMeta.touched &&
              !textMeta.error &&
              selectMeta.touched &&
              !selectMeta.error
            ) {
              setIsValid(true)
            }
            return (
              <div key={`languages[${index}].language`}>
                <DataRow
                  label=""
                  outerWrapperProps={{ mt: 0, mb: 0 }}
                  isCollapsed
                >
                  <ErrorMessage
                    collapsed={hasError && textMeta.error}
                    sx={{ mb: 1 }}
                  >
                    {textMeta.error}
                  </ErrorMessage>
                  <ErrorMessage
                    collapsed={hasError && selectMeta.error}
                    sx={{ mb: 1 }}
                  >
                    {selectMeta.error}
                  </ErrorMessage>
                </DataRow>
                <DataRow
                  key={`language-${index}`}
                  label="Language"
                  outerWrapperProps={{ mb: 1 }}
                  // gridContainerProps={{ alignItems: 'center' }}
                  flexProps={{ alignItems: 'center' }}
                >
                  <FormikTextSelectField
                    textName={textName}
                    selectName={selectName}
                    textPlaceholder="Language"
                    selectPlaceholder="Proficiency"
                    selectItems={items}
                    selectValue={language.proficiency}
                    selectProps={{
                      grey: true,
                    }}
                    handleRemove={() => arrayHelpers.remove(index)}
                    index={index}
                    hasError={hasError}
                    isValid={isValid}
                  />
                </DataRow>
              </div>
            )
          })}

          <DataRow label="" isButton>
            <Button
              variant="contained"
              color="primary"
              sizeVariant="xl"
              onClick={() => arrayHelpers.push(blankLanguage)}
              sx={{ mt: 1 }}
            >
              Add a language
            </Button>
          </DataRow>
        </>
      )}
    />
  )
}

export default memo(Languages)
