import React, { useState } from 'react'

import EditRow, { rowField } from './EditRow'
import EditProfileRowDrawer from './drawer/EditProfileRowDrawer'
import EditInterestsRowDrawer from './drawer/EditInterestsRowDrawer'

interface Props {
  type: 'profile' | 'interests'
  fields: rowField[]
  drawerHeading: string
}

const EditRowWrapper = ({ type, fields, drawerHeading }: Props) => {
  const [drawerOpen, setDrawerOpen] = useState(false)

  const props = {
    drawerOpen: drawerOpen,
    heading: drawerHeading,
    setDrawerOpen: setDrawerOpen,
    fields: fields,
  }

  return (
    <>
      <EditRow fields={fields} setDrawerOpen={setDrawerOpen} />
      {type === 'interests' && <EditInterestsRowDrawer {...props} />}
      {type === 'profile' && <EditProfileRowDrawer {...props} />}
    </>
  )
}

export default EditRowWrapper
