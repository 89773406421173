import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import { object, string } from 'yup'
import { Auth } from '@aws-amplify/auth'

import Button from '_core/components/atoms/buttons/Button'
import SaveButton from '_core/components/atoms/buttons/SaveButton'
import FormikTextField from '_core/components/atoms/inputs/formik/FormikTextField'
import PasswordHelperText from '_core/components/atoms/inputs/PasswordHelperText'

import EditDrawer from 'account/components/molecules/editRow/drawer/EditDrawer'
import { useLoading } from '_core/context/LoadingContext'
import { useMessage } from '_core/context/MessageContext'
import AWSConfig from 'account/constants/aws-config'

Auth.configure(AWSConfig)

type formValues = {
  currentPassword: string
  newPassword: string
  confirmNewPassword: string
}

type passwordCompareErrors = {
  confirmNewPassword?: string
}

const ChangePassword = () => {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const { dispatch: loadingDispatch } = useLoading()
  const { dispatch: messageDispatch } = useMessage()

  const handleSubmit = async (values: formValues) => {
    loadingDispatch({ type: 'START_LOADING' })
    try {
      const user = await Auth.currentAuthenticatedUser()
      try {
        await Auth.changePassword(
          user,
          values.currentPassword,
          values.newPassword
        )
        messageDispatch({
          type: 'SET_MESSAGE',
          payload: {
            message: 'Your password has been updated.',
            status: 'success',
          },
        })
        loadingDispatch({ type: 'STOP_LOADING' })
        setDrawerOpen(false)
      } catch (err) {
        messageDispatch({
          type: 'SET_MESSAGE',
          payload: {
            message: err.message,
            status: 'error',
          },
        })
        loadingDispatch({ type: 'STOP_LOADING' })
      }
    } catch (err) {
      messageDispatch({
        type: 'SET_MESSAGE',
        payload: {
          message: err.message,
          status: 'error',
        },
      })
      loadingDispatch({ type: 'STOP_LOADING' })
    }
  }

  let initialValues = {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  }

  const validationSchema = {
    currentPassword: string()
      .required('Please Enter your password')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/,
        'Password is not strong enough'
      ),
    newPassword: string()
      .required('Please Enter your new password')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/,
        'Password is not strong enough'
      ),
    confirmNewPassword: string().required('Re-enter your new password'),
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        sizeVariant="xl"
        onClick={() => setDrawerOpen(true)}
        sx={{ mt: 3 }}
      >
        Change Password
      </Button>
      <EditDrawer
        heading="Change password"
        setDrawerOpen={setDrawerOpen}
        drawerOpen={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={object().shape(validationSchema)}
          validate={(values) => {
            const errors: passwordCompareErrors = {}
            if (values.newPassword !== values.confirmNewPassword) {
              errors.confirmNewPassword = 'New Passwords do not match'
            }
            return errors
          }}
          onSubmit={(values) => {
            handleSubmit(values)
          }}
        >
          <Form>
            <FormikTextField
              name="oldPassword"
              label="Current password"
              placeholder="Current password"
              type="password"
              grey
              fullWidth
              sx={{ mt: 0 }}
            />
            <FormikTextField
              name="newPassword"
              label="New password"
              placeholder="New password"
              type="password"
              grey
              fullWidth
              sx={{ mt: 2 }}
            />
            <FormikTextField
              name="confirmNewPassword"
              label="Confirm new password"
              placeholder="Confirm new password"
              type="password"
              grey
              fullWidth
              sx={{ mt: 2, mb: 1 }}
            />
            <PasswordHelperText />
            <SaveButton />
          </Form>
        </Formik>
      </EditDrawer>
    </>
  )
}

export default ChangePassword
