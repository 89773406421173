import React from 'react'
import { Check } from '@mui/icons-material'

import Button, { ButtonProps } from './Button'

const SaveButton = (props: Omit<ButtonProps, 'sizeVariant' | 'children'>) => {
  return (
    <Button
      type="submit"
      variant="contained"
      color="primary"
      sizeVariant="xl"
      endIcon={<Check />}
      {...props}
      sx={{ mt: 4 }}
    >
      Save
    </Button>
  )
}

export default SaveButton
