import React, { useState, useEffect, memo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Box, Collapse, Grid } from '@mui/material'
import { useFormikContext } from 'formik'
import { ArrowDownward, ArrowUpward } from '@mui/icons-material'
import { Base64 } from 'js-base64'

import Flex from '_core/components/atoms/layout/Flex'
import FormikCheckboxButton from '_core/components/atoms/inputs/formik/FormikCheckboxButton'
import ErrorMessage from '_core/components/atoms/inputs/ErrorMessage'
import Button from '_core/components/atoms/buttons/Button'
import { allTerms } from '_core/constants/term-structure'

type termsData =
  | Queries.DatoCmsTermDepartment[]
  | Queries.DatoCmsTermRegion[]
  | Queries.DatoCmsTermLevel[]
  | Queries.DatoCmsTermLocation[]
  | Queries.DatoCmsTermProduct[]
  | Queries.DatoCmsTermSector[]

type term =
  | Queries.DatoCmsTermDepartment
  | Queries.DatoCmsTermRegion
  | Queries.DatoCmsTermLevel
  | Queries.DatoCmsTermLocation
  | Queries.DatoCmsTermProduct
  | Queries.DatoCmsTermSector

type fieldItem = {
  originalId: string
  name: string
  value: string
}

interface Props {
  termCategory: allTerms
}

const TermButtons = ({ termCategory }: Props) => {
  const data = useStaticQuery<Queries.TermButtonsQuery>(graphql`
    query TermButtons {
      allDatoCmsTermDepartment(sort: { order: ASC, fields: position }) {
        edges {
          node {
            originalId
            name
            slug
          }
        }
      }
      allDatoCmsTermRegion(sort: { order: ASC, fields: position }) {
        edges {
          node {
            originalId
            name
            slug
          }
        }
      }
      allDatoCmsTermLevel(sort: { order: ASC, fields: position }) {
        edges {
          node {
            originalId
            name
            slug
          }
        }
      }
      allDatoCmsTermLocation(sort: { order: ASC, fields: position }) {
        edges {
          node {
            originalId
            name
            slug
          }
        }
      }
      allDatoCmsTermProduct(sort: { order: ASC, fields: position }) {
        edges {
          node {
            originalId
            name
            slug
          }
        }
      }
      allDatoCmsTermSector(sort: { order: ASC, fields: position }) {
        edges {
          node {
            originalId
            name
            slug
          }
        }
      }
      allDatoCmsLanguageProficiency {
        edges {
          node {
            name
            slug
          }
        }
      }
    }
  `)

  const dataSelector = `allDatoCmsTerm${(
    termCategory.charAt(0).toUpperCase() + termCategory.slice(1)
  ).slice(0, -1)}`
  const [open, setOpen] = useState(false)

  const { getFieldMeta, values, getFieldProps } = useFormikContext()
  const meta = getFieldMeta(termCategory)
  const selectedValues = getFieldProps(termCategory).value

  let hasError = false
  if (meta.touched && meta.error) {
    hasError = true
  }

  const handleClick = () => {
    setOpen(!open)
  }

  let items: fieldItem[] = []
  data[dataSelector as keyof typeof data].edges.map((item: any) => {
    const term: term = item.node
    const valueObj = {
      slug: term.slug,
      name: term.name,
    }
    const valueString = Base64.encode(JSON.stringify(valueObj))

    items.push({
      originalId: term.originalId,
      name: term.name,
      value: valueString,
    })
  })

  let visibleTerms: fieldItem[] = items.slice(0, 12)
  let hiddenTerms: fieldItem[] = []
  if (items.length > 12) {
    hiddenTerms = items.slice(13, items.length)
  }

  useEffect(() => {
    // show hidden terms if one of those is selected
    if (selectedValues) {
      selectedValues.map((value: string) => {
        if (
          typeof hiddenTerms.find((o) => {
            return o.value === value
          }) !== 'undefined'
        ) {
          setOpen(true)
        }
      })
    }
  }, [])

  return (
    <>
      <ErrorMessage collapsed={hasError} mb={2}>
        {meta.error}
      </ErrorMessage>
      <Grid container spacing={1}>
        {visibleTerms.map((term) => {
          return (
            <Grid key={term.originalId} item xs={6} md={4} lg={3}>
              <FormikCheckboxButton
                value={term.value}
                label={term.name}
                name={termCategory}
                error={hasError}
              />
            </Grid>
          )
        })}
      </Grid>
      {hiddenTerms.length > 0 && (
        <>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box mt={1}>
              <Grid container spacing={1}>
                {hiddenTerms.map((term) => {
                  return (
                    <Grid key={term.originalId} item xs={6} md={4} lg={3}>
                      <FormikCheckboxButton
                        value={term.value}
                        label={term.name}
                        name={termCategory}
                        error={hasError}
                      />
                    </Grid>
                  )
                })}
              </Grid>
            </Box>
          </Collapse>
          <Flex center fullWidth mt={{ xs: 3 }}>
            <Button
              variant="outlined"
              sizeVariant="xl"
              colorVariant="outline-dark"
              onClick={handleClick}
            >
              <Flex alignCenter>
                <Box mr={0}>
                  Show {open ? 'Less' : 'All'} {termCategory}{' '}
                </Box>
                {open ? <ArrowUpward /> : <ArrowDownward />}
              </Flex>
            </Button>
          </Flex>
        </>
      )}
    </>
  )
}

export default memo(TermButtons)
