import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Box, styled, useTheme } from '@mui/material'

import Flex from '_core/components/atoms/layout/Flex'
import InternalButtonLink from '_core/components/atoms/buttons/InternalButtonLink'

import Avatar from 'account/components/atoms/profile/Avatar'
import UserName from 'account/components/atoms/profile/UserName'

const Button = styled(InternalButtonLink)(({ theme }) => ({
  borderColor: 'rgba(255, 255, 255, 0.2) !important',
  color: `${theme.palette.textAccountSidebar} !important`,
  fontWeight: theme.typography.fontWeightBold,
  ['&:hover']: {
    borderColor: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.primary.main} !important`,
  },
}))

const Profile = () => {
  const theme = useTheme()

  const data = useStaticQuery<Queries.ProfileSummaryQuery>(graphql`
    query ProfileSummary {
      datoCmsAppGlobal {
        profileButtonLabel
      }
    }
  `)
  const global = data.datoCmsAppGlobal
  return (
    <Flex flexDirection="column" center py={4} px={3}>
      <Avatar bgColor="#b8e4f6" sizeVariant="lg" typography="h3" />
      <Box
        component="h4"
        typography="h4Sans"
        fontSize="1.7rem"
        color="white"
        fontWeight={theme.typography.fontWeightBold}
        my={2}
      >
        <UserName />
      </Box>
      <Button
        slug="employment-profile"
        apiKey="account_page"
        variant="outlined"
        sizeVariant="xl"
        colorVariant="outline-light"
      >
        {global.profileButtonLabel}
      </Button>
    </Flex>
  )
}

export default Profile
