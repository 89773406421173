// npm
import React, { useEffect, useState } from 'react'
import { PlaylistAddCheck } from '@mui/icons-material'
//core
import InternalButtonLink from '_core/components/atoms/buttons/InternalButtonLink'
import Panel from '_core/components/molecules/panels/Panel'
import PanelFooter from '_core/components/molecules/panels/PanelFooter'
import PanelFooterButtons from '_core/components/molecules/panels/PanelFooterButtons'
//account
import ActionRequired from 'account/components/molecules/messaging/ActionRequired'
import MandateSummaryPanel from 'account/components/organisms/dashboard/MandateSummaryPanel'
import { useAccount } from 'account/context/AccountContext'

const footerButtons = [
  {
    slug: 'account/relevent-mandates',
    label: 'View Relevant Mandates',
  },
  {
    slug: 'account/interests',
    label: 'Manage My Interests',
  },
]

const RelevantMandatesPanel = () => {
  const {
    state: accountState,
    hasInterests,
    getRelevantMandateIds,
  } = useAccount()
  const [mandateIds, setMandateIds] = useState<string[] | undefined>(undefined)

  useEffect(() => {
    getRelevantMandateIds().then((result: any) => {
      let ids: string[] = []
      if (
        result &&
        result.edges &&
        Array.isArray(result.edges) &&
        result.edges.length > 0
      ) {
        result.edges.map((item: any) => {
          ids.push(item.node.id)
        })
      }
      setMandateIds(ids)
    })
  }, [])

  const panelProps = {
    panelHeading: 'My Relevant Mandates',
    panelHeadingIcon: <PlaylistAddCheck />,
  }

  return (
    <>
      {accountState.interests && hasInterests() ? (
        <MandateSummaryPanel
          {...panelProps}
          mandateIds={mandateIds}
          footerButtons={footerButtons}
          actionRequired={
            <>
              <ActionRequired>
                There are no relevant mandates that currently match your
                interests. Try adding more interests.
              </ActionRequired>
              <PanelFooterButtons footerButtons={[footerButtons[1]]} />
            </>
          }
        />
      ) : (
        <Panel
          headerProps={{
            heading: panelProps.panelHeading,
            headingIcon: panelProps.panelHeadingIcon,
            useSmallHeading: true,
          }}
        >
          <ActionRequired>
            Relevant Mandates are generated by your interests. Please setup your
            interests.
          </ActionRequired>
          <PanelFooter py={{ xs: 2 }}>
            <InternalButtonLink
              slug="account/interests"
              variant="contained"
              color="primary"
              sizeVariant="lg"
              sx={{ ml: 2 }}
            >
              Setup my interests
            </InternalButtonLink>
          </PanelFooter>
        </Panel>
      )}
    </>
  )
}

export default RelevantMandatesPanel
