import { useTheme } from '@mui/material'

export const toolbar = () => {
  const theme = useTheme()
  const styles = {
    minHeight: theme.appbarHeightSmall,
    [theme.breakpoints.up('md')]: {
      minHeight: theme.appbarHeightLarge,
    },
  }
  return styles
}
