import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Container, Skeleton } from '@mui/material'

import { useLoading } from '_core/context/LoadingContext'
import { useAccount } from 'account/context/AccountContext'
import Layout from 'account/components/organisms/global/Layout'
import InterestsForm from 'account/components/organisms/interests/InterestsForm'
import profileFields from 'account/helpers/profileFields'
import InterestsGuidance from 'account/components/molecules/interests/InterestsGuidance'

import SectionHeading from 'public/components/molecules/sections/SectionHeading'

export type allTermsDataProps = {
  departments: any[]
  regions: any[]
  levels: any[]
  locations: any[]
  products: any[]
  sectors: any[]
}

const EmploymentProfile = () => {
  const { state: accountState } = useAccount()
  const [count, setCount] = useState(0)
  const [loaded, setLoaded] = useState(false)
  const { state: loadingState } = useLoading()

  const data = useStaticQuery<Queries.InterestsQuery>(graphql`
    query Interests {
      datoCmsAppAccountPage(originalId: { eq: "32377979" }) {
        ...accountPage
        guidanceText
        guidanceButton {
          ...appButton
        }
      }
      allDatoCmsAppProfileField {
        edges {
          node {
            ...ProfileField
          }
        }
      }
    }
  `)

  const formikData = profileFields({
    cmsProfileFields: data.allDatoCmsAppProfileField.edges,
  })
  const page = data.datoCmsAppAccountPage

  return (
    <Layout
      slug={page.slug}
      metadata={{
        seoTags: page.seoMetaTags,
      }}
    >
      <SectionHeading
        heading={page.title}
        boxProps={{
          mt: 8,
        }}
      />

      <InterestsGuidance
        text={page.guidanceText}
        button={page.guidanceButton[0]}
      />

      {accountState && accountState.fetched.interests ? (
        <InterestsForm validation={formikData.validation} />
      ) : (
        <>Hllo</>
      )}
    </Layout>
  )
}

export default EmploymentProfile
