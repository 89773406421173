import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import MandateLanding from './templates/MandateLanding'

const FavouriteMandates = () => {
  const data = useStaticQuery<Queries.FavouriteMandatesQuery>(graphql`
    query FavouriteMandates {
      datoCmsAppAccountPage(originalId: { eq: "32377936" }) {
        ...accountPage
        requiredMessage
        requiredButton {
          ...appButton
        }
      }
    }
  `)

  return (
    <MandateLanding
      data={data?.datoCmsAppAccountPage}
      stateType="favoriteMandate"
    />
  )
}

export default FavouriteMandates
