// npm
import React, { memo, useRef, useState } from 'react'
import { Box, Slider, Stack, styled } from '@mui/material'
import { Refresh, ZoomIn, ZoomOut } from '@mui/icons-material'

// core
import AvatarEditor from 'react-avatar-editor'
import Button from '_core/components/atoms/buttons/Button'
import SaveButton from '_core/components/atoms/buttons/SaveButton'
import FileUpload, {
  FileUploadProps,
} from '_core/components/atoms/inputs/FileUpload'

//account
import EditDrawer from 'account/components/molecules/editRow/drawer/EditDrawer'
import { awsStorageResult, useAccount } from 'account/context/AccountContext'

const StyledEditDrawer = styled(EditDrawer)(({ theme }) => ({
  ['.MuiPaper-root']: {
    left: 'auto !important',
  },
}))

interface Props extends FileUploadProps {
  name: string
  hideError?: true
}

const AvatarImage = ({ name, hideError, ...props }: Props) => {
  const { state, upload, update } = useAccount()
  const editor = useRef(null)
  const [scale, setScale] = useState<number>(1.2)
  const [rotate, setRotate] = useState<number>(0)
  const [imgSrc, setImgSrc] = useState('')
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [fileValue, setFileValue] = useState<File>()

  const handleSubmit = async () => {
    if (editor && editor.current) {
      const canvasScaled = editor.current.getImageScaledToCanvas()
      canvasScaled.toBlob((blob: Blob) => {
        if (blob) {
          upload({
            file: blob,
            filename: fileValue.name,
            folder: 'images',
          }).then((result: awsStorageResult) => {
            const tempAccount = { ...state.account }
            delete tempAccount.email

            const dataObj = {
              account: {
                ...tempAccount,
                picture: result.key,
                privacyPolicy:
                  state && state.account.privacyPolicy
                    ? state.account.privacyPolicy
                    : true,
              },
            }
            update({ type: 'account', variables: dataObj })
            setDrawerOpen(false)
            setRotate(0)
            setScale(1.2)
            setImgSrc('')
          })
        }
      })
    }
  }

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () =>
        setImgSrc(reader.result.toString() || '')
      )
      reader.readAsDataURL(e.target.files[0])
      setFileValue(e.target.files[0])
      setDrawerOpen(true)
    }
  }

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setScale(newValue as number)
  }

  return (
    <>
      <FileUpload
        name={name}
        {...props}
        inputProps={{
          onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
            onSelectFile(event),
        }}
      />

      <StyledEditDrawer
        heading="Crop your image"
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
      >
        <div className="App">
          {Boolean(imgSrc) && (
            <>
              <AvatarEditor
                ref={editor}
                image={imgSrc}
                width={340}
                height={340}
                borderRadius={200}
                border={5}
                color={[255, 255, 255, 1]}
                scale={scale}
                rotate={rotate}
              />
              <Box mt={2} textAlign="center">
                Drag the image inside the circle to reposition.
              </Box>
              <Box>
                <Stack spacing={2} direction="row" mt={1} alignItems="center">
                  <ZoomOut />
                  <Slider
                    value={scale}
                    min={1}
                    max={2}
                    step={0.01}
                    onChange={handleSliderChange}
                  />
                  <ZoomIn />

                  <Stack
                    spacing={1}
                    direction="row"
                    mt={1}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Button
                      round
                      sizeVariant="lg"
                      colorVariant="contained-light"
                      onClick={() => setRotate(rotate - 90)}
                      sx={{
                        svg: {
                          fontSize: '2rem !important',
                          transform: 'scaleX(-1)',
                        },
                      }}
                    >
                      <Refresh />
                    </Button>
                    <Button
                      round
                      sizeVariant="lg"
                      colorVariant="contained-light"
                      onClick={() => setRotate(rotate + 90)}
                      sx={{
                        svg: {
                          fontSize: '2rem !important',
                        },
                      }}
                    >
                      <Refresh />
                    </Button>
                  </Stack>
                </Stack>

                <SaveButton fullWidth onClick={handleSubmit} />
              </Box>
            </>
          )}
        </div>
      </StyledEditDrawer>
    </>
  )
}
export default memo(AvatarImage)
