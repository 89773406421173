import React, { memo } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Share } from '@mui/icons-material'
import { Box, styled } from '@mui/material'

import Card from '_core/components/atoms/cards/Card'
import CardHeading from '_core/components/atoms/cards/CardHeading'
import InternalLink from '_core/components/atoms/links/InternalLink'
import Flex from '_core/components/atoms/layout/Flex'
import Button from '_core/components/atoms/buttons/Button'
import ImageHover from '_core/components/atoms/media/ImageHover'
import TimeAgo from 'public/components/atoms/date/TimeAgo'
import ShareDialog, {
  ShareDialogHandle,
} from '_core/components/molecules/dialogs/ShareDialog'

interface Props {
  post: Queries.DatoCmsBlogPost
  isDynamic?: boolean
}

const AuthorImagesFlex = styled(Flex)({
  ['.gatsby-image-wrapper']: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    border: '2px solid white',
    marginLeft: '-20px',
    overflow: 'hidden',
    ['&:first-of-type']: {
      marginLeft: 0,
    },
  },
})

const StyledCard = styled(Card)({
  ['@media not all and (min-resolution:.001dpcm)']: {
    ['@media']: {
      transform: 'translateZ(0) !important',
    },
  },
})

const BlogPostCard = ({ post, isDynamic }: Props) => {
  const dialogRef = React.useRef<ShareDialogHandle>(null)
  let authorNames = ''
  let pre = ''

  if (post.authors.length > 2) {
    authorNames = 'multiple authors'
  } else {
    post.authors.map((author: Queries.DatoCmsTeamMember, index: number) => {
      if (index > 0) {
        pre = ' \n& '
      }
      authorNames += pre + author.name
    })
  }

  const StyledLink = styled(InternalLink)`
    overflow: hidden;
    display: block;
  `

  // @ts-ignore
  const apiKey = isDynamic ? post._modelApiKey : post.model.apiKey
  return (
    <StyledCard id={isDynamic ? post.id : post.originalId} fullHeight>
      <StyledLink slug={post.slug} apiKey={apiKey} aria-label="Read more">
        <ImageHover>
          {isDynamic ? (
            <div className="gatsby-image-wrapper dynamic">
              <img
                src={`${post.image.url}?w=600&ar=4:3&fit=crop`}
                alt="test"
                style={{ width: '100%', display: 'block' }}
              />
            </div>
          ) : (
            <GatsbyImage image={post.image.gatsbyImageData} alt="" />
          )}
        </ImageHover>
      </StyledLink>
      <Box px={2} pt={3} mb="auto">
        <TimeAgo date={post.publishDate} />
        <InternalLink slug={post.slug} apiKey={apiKey} aria-label="Read more">
          <CardHeading sx={{ mt: 2 }}>{post.title}</CardHeading>
        </InternalLink>
      </Box>
      <Flex px={2} pb={2}>
        <Flex alignCenter fullWidth>
          <AuthorImagesFlex alignCenter>
            {isDynamic ? (
              <>
                {post.authors.map((author: Queries.DatoCmsTeamMember) => (
                  <div
                    className="gatsby-image-wrapper"
                    key={isDynamic ? author.id : author.originalId}
                  >
                    <img
                      src={`${author.image.url}?w=50&h=50&fit=crop`}
                      alt=""
                      style={{ width: '100%', display: 'block' }}
                    />
                  </div>
                ))}
              </>
            ) : (
              <>
                {post.authors.map((author: Queries.DatoCmsTeamMember) => (
                  <GatsbyImage
                    key={`${isDynamic ? post.id : post.originalId}-${
                      isDynamic ? author.id : author.originalId
                    }`}
                    image={author.profileImage.gatsbyImageData}
                    alt={`Picture of ${author.name}`}
                  />
                ))}
              </>
            )}
          </AuthorImagesFlex>
          <Box ml={1} mr="auto" className="authors">
            {authorNames}
          </Box>
          <Button
            sizeVariant="md"
            round
            colorVariant="outline-dark"
            variant="outlined"
            aria-label="Share"
            onClick={() => dialogRef?.current?.handleOpen()}
          >
            <Share className="icon" fontSize="inherit" />
          </Button>
        </Flex>

        <ShareDialog
          ref={dialogRef}
          id={`${isDynamic ? post.id : post.originalId}`}
          shareButtonsProps={{
            description: post.title,
            url: `blog/${post.slug}`,
            contentType: post.blogType.name,
            source: 'card',
          }}
        />
      </Flex>
    </StyledCard>
  )
}

export default memo(BlogPostCard)
