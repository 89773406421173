import React, { memo } from 'react'

import { Box, Drawer, DrawerProps, styled } from '@mui/material'

import { toolbar } from 'account/styles/toolbar'

import Flex from '_core/components/atoms/layout/Flex'
import CloseButton from '_core/components/atoms/buttons/CloseButton'

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  zIndex: theme.zIndex.snackbar - 1,
  ['.MuiPaper-root']: {
    left: 0,
    overflowX: 'hidden',
    [theme.breakpoints.up('lg')]: {
      left: '30%',
    },
    [theme.breakpoints.up('xl')]: {
      left: '50%',
    },
  },
}))

const DrawerHeader = styled('div')(({ theme }) => ({
  ...toolbar(),
  borderBottom: `1px solid ${theme.palette.outlineDark}`,
}))

interface Props extends DrawerProps {
  heading: string
  children: React.ReactNode
  drawerOpen?: boolean
  setDrawerOpen: Function
}

const EditDrawer = ({
  heading,
  children,
  drawerOpen,
  setDrawerOpen,
  ...props
}: Props) => {
  return (
    <StyledDrawer
      variant="temporary"
      anchor="right"
      open={drawerOpen}
      ModalProps={{ disablePortal: true }}
      {...props}
    >
      <DrawerHeader>
        <Box px={{ xs: 3, sm: 4, md: 5, lg: 6 }} style={{ height: '100%' }}>
          <Flex alignCenter full>
            <Box
              component="h2"
              typography="h5"
              m="0px"
              fontFamily="typography.fontFamily"
              fontWeight="typography.fontWeightSemiBold"
            >
              {heading}
            </Box>
            <CloseButton
              withoutLabel
              sizeVariant="lg"
              sx={{
                ml: 'auto',
              }}
              onClick={() => setDrawerOpen(false)}
            />
          </Flex>
        </Box>
      </DrawerHeader>

      <Box
        py={{ xs: 3, lg: 5 }}
        px={{ xs: 3, sm: 4, md: 5, lg: 6 }}
        fontSize="1.6rem"
      >
        {children}
      </Box>
    </StyledDrawer>
  )
}

export default memo(EditDrawer)
