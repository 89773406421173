import React from 'react'
import { Box, Divider, useTheme, useMediaQuery } from '@mui/material'
import { Formik, Form } from 'formik'
import { Base64 } from 'js-base64'

import SaveButton from '_core/components/atoms/buttons/SaveButton'

import { useAccount } from 'account/context/AccountContext'
import { term } from 'account/context/accountReducer'
import { rowField } from 'account/components/molecules/editRow/EditRow'
import EditDrawer from 'account/components/molecules/editRow/drawer/EditDrawer'
import { initialValues } from 'account/helpers/profileFields'
import { ScrollToFieldError } from '_core/components/atoms/inputs/formik/ScrollToFieldError'

type formValues = {
  departments?: string[]
  regions?: string[]
  levels?: string[]
  locations?: string[]
}

interface Props {
  fields: rowField[]
  heading: string
  drawerOpen?: boolean
  setDrawerOpen: Function
}

const EditInterestsRowDrawer = ({
  fields,
  heading,
  drawerOpen,
  setDrawerOpen,
}: Props) => {
  const { state: accountState, update } = useAccount()
  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'))

  const handleSubmit = (values: formValues) => {
    let updates: any = {}
    setDrawerOpen(false)

    fields.map((field: rowField) => {
      const value = values[field.fieldId as keyof typeof values]
      let terms: term[] = []
      if (field.isTerms && Array.isArray(value)) {
        value.map((item: any, index: number) => {
          if (typeof item === 'string') {
            terms.push(JSON.parse(Base64.decode(item)))
          }
        })
      }
      updates[field.fieldId] = terms
    })

    const dataObj = {
      interests: {
        ...accountState.interests,
        ...updates,
      },
    }
    // update and set status message
    update({ type: 'interests', variables: dataObj })
  }

  let initialValues: initialValues = {}

  fields.map((field: rowField) => {
    if (field.isTerms && Array.isArray(field.value) && field.value.length > 0) {
      let encodedArray: string[] = []
      field.value.map((item) => {
        encodedArray.push(Base64.encode(JSON.stringify(item)))
      })

      initialValues[field.fieldId as keyof typeof initialValues] = encodedArray
    }
  })

  return (
    <EditDrawer
      heading={heading}
      setDrawerOpen={setDrawerOpen}
      drawerOpen={drawerOpen}
      onClose={() => setDrawerOpen(false)}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          handleSubmit(values)
        }}
      >
        <Form>
          <ScrollToFieldError />
          {fields.map((field, index) => {
            return (
              <div key={`edit-field-${field.fieldId}`}>
                {index > 0 ? (
                  <Box mt={{ xs: 3 }} fontSize="1.6rem">
                    {field.drawerComponent}
                  </Box>
                ) : (
                  <>{field.drawerComponent}</>
                )}
              </div>
            )
          })}

          <Divider sx={{ mt: 4 }} />
          <SaveButton fullWidth={smUp ? false : true} />
        </Form>
      </Formik>
    </EditDrawer>
  )
}

export default EditInterestsRowDrawer
