import React, { useEffect, useState } from 'react'
import { Box, Divider, FormControl, FormGroup } from '@mui/material'
import { Assignment } from '@mui/icons-material'

import InternalButtonLink from '_core/components/atoms/buttons/InternalButtonLink'
import DataRow from '_core/components/molecules/panels/DataRow'
import Panel from '_core/components/molecules/panels/Panel'
import PanelFooter from '_core/components/molecules/panels/PanelFooter'
import PanelFooterButtons from '_core/components/molecules/panels/PanelFooterButtons'
import TermButtonQueryGroup from '_core/components/molecules/buttonGroups/TermButtonQueryGroup'
import termStructure, { termProps } from '_core/constants/term-structure'
import TermButton from '_core/components/atoms/buttons/termButtons/TermButton'

import ActionRequired from 'account/components/molecules/messaging/ActionRequired'
import { useAccount } from 'account/context/AccountContext'

const InterestsPanel = () => {
  const [loaded, setLoaded] = useState(false)
  const { state: accountState, hasInterests } = useAccount()

  const footerButtons = [
    {
      slug: 'account/interests',
      label: 'Edit Interests',
    },
  ]

  const headingContainerProps = {
    pt: { xs: 2 },
    pb: { xs: 1, lg: 2, xl: 3 },
    px: { xs: 2, sm: 3, lg: 4 },
  }

  const dataRowProps = {
    outerWrapperProps: {
      py: { xs: 1 },
      px: { xs: 2, sm: 3, lg: 4 },
    },
  }
  useEffect(() => {
    if (accountState.interests) {
      setLoaded(true)
    }
  }, [])

  return (
    <Panel
      headerProps={{
        heading: 'My Interests',
        useSmallHeading: true,
        headingIcon: <Assignment />,
      }}
    >
      {accountState.interests && hasInterests() ? (
        <>
          {Object.keys({
            ...termStructure.primary,
          }).map((key: string, index: number) => {
            const term: termProps =
              termStructure.primary[key as keyof typeof termStructure.primary]

            return (
              <DataRow
                key={`interests-row-${key}-${index}`}
                label={`${term.name}:`}
                {...dataRowProps}
              >
                {accountState?.interests &&
                accountState?.interests[
                  key as keyof typeof accountState.interests
                ] &&
                accountState?.interests[
                  key as keyof typeof accountState.interests
                ].length > 0 ? (
                  <TermButtonQueryGroup
                    keyId={key}
                    // @ts-ignore
                    category={key}
                    filterTerms={
                      accountState?.interests[
                        key as keyof typeof accountState.interests
                      ]
                    }
                  />
                ) : (
                  <TermButton key={`interests-empty-${key}`} slug="" disabled>
                    Not set
                  </TermButton>
                )}
              </DataRow>
            )
          })}

          <PanelFooterButtons footerButtons={footerButtons} sx={{ mt: 1 }} />
        </>
      ) : (
        <>
          <ActionRequired>You have not yet setup your interests</ActionRequired>
          <PanelFooter py={{ xs: 2 }}>
            <InternalButtonLink
              slug="account/interests"
              variant="contained"
              color="primary"
              sizeVariant="lg"
              sx={{ ml: '2' }}
            >
              Setup my Interests
            </InternalButtonLink>
          </PanelFooter>
        </>
      )}
    </Panel>
  )
}

export default InterestsPanel
