import React, { useState, useEffect } from 'react'
import { InView } from 'react-intersection-observer'
import { Box, styled } from '@mui/material'
import { Bookmark, List, PlaylistAddCheck, TrendingUp } from '@mui/icons-material'
import CountUp from 'react-countup'

import { hexToRgb } from '_core/helpers/hexToRgb'

import { useAccount } from 'account/context/AccountContext'
import Flex from '_core/components/atoms/layout/Flex'

export type statBoxType = 'applications' | 'favorites' | 'marketUpdates' | 'relevant'

interface IconWrapperProps {
  type: statBoxType
}
interface Props extends IconWrapperProps {
  label: string
}

const IconWrapper = styled(Flex, {
  shouldForwardProp: (prop) => prop !== 'type',
})<IconWrapperProps>(({ type, theme }) => ({
  width: '70px',
  height: '70px',
  borderRadius: '50%',
  svg: {
    fontSize: '30px',
  },
  ...(type === 'applications' && {
    backgroundColor: 'rgba(247,146,86,.12)',
    color: 'rgba(247,146,86,1)',
  }),
  ...(type === 'favorites' && {
    backgroundColor: 'rgba(246,84,117,.12)',
    color: 'rgba(246,84,117,1)',
  }),
  ...(type === 'marketUpdates' && {
    backgroundColor: 'rgba(65,211,189,.12)',
    color: 'rgba(65,211,189,1)',
  }),
  ...(type === 'relevant' && {
    backgroundColor: hexToRgb(theme.palette.primary.main, 0.12),
    color: theme.palette.primary.main,
  }),
}))
const ValueWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'type',
})<IconWrapperProps>(({ type, theme }) => ({
  fontSize: '4rem',
  fontWeight: theme.typography.fontWeightSemiBold,
  lineHeight: 1,
  ...(type === 'applications' && {
    color: 'rgba(247,146,86,1)',
  }),
  ...(type === 'favorites' && {
    color: 'rgba(246,84,117,1)',
  }),
  ...(type === 'marketUpdates' && {
    color: 'rgba(65,211,189,1)',
  }),
  ...(type === 'relevant' && {
    color: theme.palette.primary.main,
  }),
}))

const StatBox = ({ type, label }: Props) => {
  const { state, hasInterests, getRelevantMandateIds } = useAccount()
  const [visible, setVisible] = useState<boolean>(false)
  const [value, setValue] = useState<number>(0)

  let icon
  switch (type) {
    case 'favorites':
      icon = <Bookmark />
      break
    case 'applications':
      icon = <List />
      break
    case 'marketUpdates':
      icon = <TrendingUp />
      break
    case 'relevant':
      icon = <PlaylistAddCheck />
      break
  }

  useEffect(() => {
    if (state && state.account && value === 0) {
      switch (type) {
        case 'favorites':
          if (state.favoriteMandate && state.favoriteMandate.length > 0) {
            setValue(state.favoriteMandate.length)
          }
          break
        case 'applications':
          if (state.applications && state.applications.length > 0) {
            setValue(state.applications.length)
          }
          break
        case 'marketUpdates':
          if (state.votes && state.votes.length > 0) {
            setValue(state.votes.length)
          }
          break
        case 'relevant':
          if (state.interests && hasInterests()) {
            getRelevantMandateIds().then((result: any) => {
              if (result && result.edges && result.edges.length > 0) {
                setValue(result.edges.length)
              }
            })
          }
          break
      }
    }
  }, [state])

  return (
    <InView
      as="div"
      onChange={(inView) => {
        if (inView) {
          setVisible(true)
        }
      }}
    >
      <Box p={2}>
        <Flex alignCenter>
          <IconWrapper type={type} center>
            {icon}
          </IconWrapper>
          <Box ml={2}>
            <ValueWrapper type={type}>
              <CountUp end={value} separator="," duration={1} />
            </ValueWrapper>
            <Box typography="smallText">{label}</Box>
          </Box>
        </Flex>
      </Box>
    </InView>
  )
}

export default StatBox
