import React from 'react'
import { Box, useTheme, useMediaQuery } from '@mui/material'

import FormikSlider from '_core/components/atoms/inputs/formik/FormikSlider'

const createMarks = (min: number, max: number, increment: number) => {
  let marks = []
  for (let i = min; i <= max; i = i + increment) {
    marks.push({ value: i, label: i })
  }
  return marks
}

interface Props {
  fieldName: string
}

const Experience = ({ fieldName }: Props) => {
  const theme = useTheme()
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))
  return (
    <Box pb={{ xs: 2 }} px={1}>
      <FormikSlider
        name={fieldName}
        step={1}
        min={0}
        max={50}
        marks={lgDown ? createMarks(0, 50, 10) : createMarks(0, 50, 5)}
        valueLabelDisplay="on"
      />
    </Box>
  )
}

export default Experience
