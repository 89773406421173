import React from 'react'
import { Box } from '@mui/material'

const PasswordHelperText = () => {
  return (
    <Box fontSize="1.3rem" textAlign="left" color="textGrey" mb={3}>
      Password must be at least 8 characters and must contain at least one
      uppercase letter, one lowercase letter and one number
    </Box>
  )
}

export default PasswordHelperText
