// npm
import React from 'react'
import { Box, Divider, Skeleton } from '@mui/material'
//core
import Panel from '_core/components/molecules/panels/Panel'
import PanelCard from '_core/components/molecules/panels/PanelCard'
import PanelFooterButtons, {
  footerButton,
} from '_core/components/molecules/panels/PanelFooterButtons'
import { dynamicMandatesQuery } from '_core/constants/dynamic-data-queries'
import useDynamicData from '_core/helpers/useDynamicData'

interface Props {
  panelHeading: string
  panelHeadingIcon: React.ReactNode
  mandateIds: string[] | undefined
  footerButtons: footerButton[]
  actionRequired: React.ReactNode
}

const MandateSummaryPanel = ({
  panelHeading,
  panelHeadingIcon,
  mandateIds,
  footerButtons,
  actionRequired,
}: Props) => {
  const { contentProps } = useDynamicData({
    perPage: 12,
    dynamicType: 'mandate',
    dataResultSelector: 'mandates',
    dynamicContentQuery: dynamicMandatesQuery,
    ids: mandateIds ? mandateIds : [],
  })

  return (
    <Panel
      headerProps={{
        heading: panelHeading,
        useSmallHeading: true,
        headingIcon: panelHeadingIcon,
      }}
    >
      {!contentProps.fetched ? (
        <>
          <Box py={2} px={4}>
            <Skeleton animation="wave" />
          </Box>
          <Divider />
          <Box py={2} px={4}>
            <Skeleton animation="wave" />
          </Box>
          <Divider />
          <Box py={2} px={4}>
            <Skeleton animation="wave" />
          </Box>
        </>
      ) : (
        <>
          {contentProps &&
          contentProps.items &&
          contentProps.items.length > 0 ? (
            <>
              <div>
                {contentProps.items.map((item: any, index: number) => {
                  const mandate: Queries.DatoCmsPageMandate = item.node
                  if (index < 3) {
                    // @ts-ignore
                    const apiKey = mandate._modelApiKey
                    return (
                      <PanelCard
                        key={`rm-${
                          mandate.originalId ? mandate.originalId : mandate.id
                        }`}
                        heading={mandate.title}
                        slug={mandate.slug}
                        apiKey={apiKey}
                      />
                    )
                  }
                })}
              </div>
              <PanelFooterButtons footerButtons={footerButtons} noBorder />
            </>
          ) : (
            <>{actionRequired}</>
          )}
        </>
      )}
    </Panel>
  )
}

export default MandateSummaryPanel
