import React, { memo } from 'react'

import { FlexProps } from '_core/components/atoms/layout/Flex'
import FormikLinkedIn from '_core/components/atoms/inputs/formik/FormikLinkedIn'
import FormikFileUpload from '_core/components/atoms/inputs/formik/FormikFileUpload'

interface Props {
  cvProps: {
    name: string
  }
  linkedInProps: FlexProps
}

const History = ({ cvProps, linkedInProps }: Props) => {
  return (
    <>
      <FormikFileUpload
        {...cvProps}
        id="cvUpload"
        accept=".pdf, .doc, .docx"
        label="Upload CV"
        helperText="Your CV must be a either pdf or word file."
      />
      <FormikLinkedIn {...linkedInProps} />
    </>
  )
}

export default memo(History)
