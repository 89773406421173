import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Grid } from '@mui/material'
import * as Yup from 'yup'
import { Base64 } from 'js-base64'

import termStructure from '_core/constants/term-structure'

import { useAccount } from 'account/context/AccountContext'
import {
  language,
  profile,
  profileFieldValues,
  profileTerms,
} from 'account/context/accountReducer'
import { languageFieldValue } from 'account/constants/languages'

import ProfileStepPanel from './ProfileStepPanel'
import Experience from '../steps/Experience'
import Employer from '../steps/Employer'
import History from '../steps/History'
import Contact from '../steps/Contact'
import Languages from '../steps/Languages'
import TermButtons from '../steps/TermButtons'

import WizardWrapper from './WizardWrapper'
import { initialValues, validation } from 'account/helpers/profileFields'

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))
type WizardStepProps = { children: React.ReactNode }
const WizardStep = ({ children }: WizardStepProps) => <>{children}</>

interface Props {
  initialValues: initialValues
  validation: validation
}

interface GridLayoutProps {
  children: React.ReactNode
}

const GridLayout = ({ children }: GridLayoutProps) => {
  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={12} lg={8}>
        {children}
      </Grid>
    </Grid>
  )
}

const ProfileWizard = ({ initialValues, validation }: Props) => {
  const { upload, update: accountUpdate } = useAccount()

  const data = useStaticQuery<Queries.ProfileWizardQuery>(graphql`
    query ProfileWizard {
      datoCmsAppEmploymentProfile {
        wizardSteps {
          ... on DatoCmsAppProfileStepSingle {
            originalId
            model {
              apiKey
            }
            field {
              fieldId
              name
            }
            headingPrefix
            heading
            stepId
          }
          ... on DatoCmsAppProfileStepGrouped {
            id
            originalId
            model {
              apiKey
            }
            stepId
            groups {
              groupType
              headingPrefix
              heading
              model {
                apiKey
              }
              originalId
              profileFields {
                fieldId
                name
              }
            }
          }
        }
      }
    }
  `)

  const steps = data.datoCmsAppEmploymentProfile?.wizardSteps

  const panelContentProps = {
    p: {
      xs: 4,
      xl: 6,
    },
  }

  const fieldSpacing = {
    xs: 3,
    sm: 4,
  }

  const profileSubmit = (values: profileFieldValues) => {
    // Prepare terms
    let profileTerms: profileTerms = {
      departments: [],
      regions: [],
      levels: [],
      locations: [],
      products: [],
      sectors: [],
    }
    const termCategories = [
      ...Object.keys(termStructure.primary),
      ...Object.keys(termStructure.secondary),
    ]
    termCategories.map((category: string) => {
      if (
        category !== 'responsibilities' &&
        category !== 'languages' &&
        values[category as keyof typeof values]
      ) {
        const termValues = values[category as keyof typeof values]
        profileTerms[category as keyof typeof profileTerms] = []
        termValues.map((item: string) => {
          profileTerms[category as keyof typeof profileTerms].push(
            JSON.parse(Base64.decode(item))
          )
        })
      }
    })

    // Prepare languages
    let languages: language[] = []
    if (values.languages && values.languages.length > 0) {
      values.languages.map((lang: languageFieldValue) => {
        languages.push({
          language: lang.language,
          proficiency: JSON.parse(Base64.decode(lang.proficiency)),
        })
      })
    }

    type dataObject = {
      profile: profile
    }
    type result = {
      key: string
    }
    upload({
      file: values.cv,
      filename: values.cv.name,
      folder: 'images',
    }).then((result: result) => {
      if (result.key) {
        const dataObj: dataObject = {
          profile: {
            ...profileTerms,
            yearsOfExperience: values.yearsOfExperience,
            currentEmployer: {
              employer: values.employer,
              jobTitle: values.jobTitle,
            },
            cv: result.key,
            linkedIn: values.linkedIn,
            languages: languages,
            contactInformation: {
              mobileNumber: values.mobileNumber,
            },
          },
        }
        accountUpdate({ type: 'profile', variables: dataObj })
        if (typeof window !== 'undefined') {
          window.gtag('event', 'employment_profile_completed', {
            action: 'Employment Profile Completed',
          })
        }
      }
    })
  }

  return (
    <WizardWrapper
      initialValues={initialValues}
      onSubmit={async (values: profileFieldValues) =>
        sleep(300).then(() => {
          profileSubmit(values)
        })
      }
    >
      {steps?.map(
        (
          step:
            | Queries.DatoCmsAppProfileStepGrouped
            | Queries.DatoCmsAppProfileStepSingle,
          index: number
        ) => {
          if (step?.model?.apiKey === 'profile_step_single') {
            switch (step.stepId) {
              case 'yearsOfExperience':
                return (
                  <WizardStep
                    key={`wizard-step-${step.stepId}`}
                    stepId={step.stepId}
                    // onSubmit={() =>
                    //   console.log(`${index + 1}. ${step.heading} submitted`)
                    // }
                    validationSchema={Yup.object({
                      [step.stepId]: validation[step.stepId],
                    })}
                  >
                    <ProfileStepPanel
                      contentProps={panelContentProps}
                      panelProps={{
                        headerProps: {
                          headingPrefix: step.headingPrefix,
                          heading: step.heading,
                          useSmallHeading: true,
                        },
                      }}
                    >
                      <Experience fieldName="yearsOfExperience" />
                    </ProfileStepPanel>
                  </WizardStep>
                )
              case 'departments':
              case 'regions':
              case 'levels':
              case 'locations':
              case 'products':
              case 'sectors':
                return (
                  <WizardStep
                    key={`wizard-step-${step.stepId}`}
                    stepId={step.stepId}
                    // onSubmit={() =>
                    //   console.log(`${index + 1}. ${step.heading} submitted`)
                    // }
                    validationSchema={Yup.object({
                      [step.stepId]: validation[step.stepId],
                    })}
                  >
                    <ProfileStepPanel
                      contentProps={panelContentProps}
                      panelProps={{
                        headerProps: {
                          headingPrefix: step.headingPrefix,
                          heading: step.heading,
                          useSmallHeading: true,
                        },
                      }}
                    >
                      <TermButtons termCategory={step.stepId} />
                    </ProfileStepPanel>
                  </WizardStep>
                )
            }
          }
          if (step?.model?.apiKey === 'profile_step_grouped') {
            let validationObj = {}
            let fieldComponent: React.ReactNode

            // get all validation
            {
              step.groups.map((group: Queries.DatoCmsAppProfileStepGroup) => {
                group?.profileFields.map(
                  (field: Queries.DatoCmsAppProfileField) => {
                    validationObj[field.fieldId] = validation[field.fieldId]
                  }
                )
              })
            }

            return (
              <WizardStep
                key={`wizard-step-${step.stepId}`}
                stepId={step.stepId}
                // onSubmit={() =>
                //   console.log(`${index + 1}. ${step.heading} submitted`)
                // }
                validationSchema={Yup.object(validationObj)}
              >
                {step.groups.map(
                  (
                    group: Queries.DatoCmsAppProfileStepGroup,
                    index: number
                  ) => {
                    switch (group.groupType) {
                      case 'employer': {
                        const employerField = group.profileFields.filter(
                          (field: Queries.DatoCmsAppProfileField) =>
                            field.fieldId === 'employer'
                        )[0]
                        const jobTitleField = group.profileFields.filter(
                          (field: Queries.DatoCmsAppProfileField) =>
                            field.fieldId === 'jobTitle'
                        )[0]
                        fieldComponent = (
                          <Employer
                            employerProps={{
                              name: employerField?.fieldId,
                              label: employerField?.name,
                              placeholder: employerField?.name,
                            }}
                            jobTitleProps={{
                              name: jobTitleField?.fieldId,
                              label: jobTitleField?.name,
                              placeholder: jobTitleField?.name,
                              sx: {
                                mt: {
                                  ...fieldSpacing,
                                },
                              },
                            }}
                          />
                        )
                        break
                      }
                      case 'history': {
                        fieldComponent = (
                          <History
                            cvProps={{
                              name: 'cv',
                            }}
                            linkedInProps={{
                              sx: {
                                mt: {
                                  ...fieldSpacing,
                                },
                              },
                            }}
                          />
                        )
                        break
                      }
                      case 'additionalContact':
                        fieldComponent = <Contact fieldName="mobileNumber" />
                        break
                      case 'languageProficiencies':
                        fieldComponent = <Languages fieldName="languages" />
                        break
                    }

                    return (
                      <ProfileStepPanel
                        key={`${group.originalId}-${index}`}
                        contentProps={panelContentProps}
                        panelProps={{
                          headerProps: {
                            headingPrefix: group.headingPrefix,
                            heading: group.heading,
                            useSmallHeading: true,
                          },
                        }}
                      >
                        <GridLayout>{fieldComponent}</GridLayout>
                      </ProfileStepPanel>
                    )
                  }
                )}
              </WizardStep>
            )
          }
        }
      )}
    </WizardWrapper>
  )
}

export default ProfileWizard
