import React, { memo } from 'react'
import { useFormikContext } from 'formik'

import DataRow from './DataRow'
import ErrorMessage from '_core/components/atoms/inputs/ErrorMessage'

interface Props {
  fieldName: string
  children: React.ReactNode
}

const DataRowErrorWrapper = ({ fieldName, children }: Props) => {
  const { getFieldMeta } = useFormikContext()
  const meta = getFieldMeta(fieldName)

  let hasError = false
  if (meta.touched && meta.error) {
    hasError = true
  }

  return (
    <>
      <DataRow
        label=""
        outerWrapperProps={{
          mt: '0px',
          mb: hasError ? 1 : 0,
          className: 'auto-height',
        }}
        isCollapsed
      >
        <ErrorMessage collapsed={hasError}>{meta.error}</ErrorMessage>
      </DataRow>
      {children}
    </>
  )
}

export default memo(DataRowErrorWrapper)
