import React, { useState } from 'react'
import { Box, Container, LinearProgress } from '@mui/material'
// @ts-ignore
import { HelmetDatoCms } from 'gatsby-source-datocms'

import { useLoading } from '_core/context/LoadingContext'
import Flex from '_core/components/atoms/layout/Flex'
import Section from '_core/components/atoms/layout/Section'
import Head from '_core/components/organisms/global/Head'
import StatusMessage from '_core/components/atoms/feedback/StatusMessage'
import { customSeoTags, CustomSeoTagsProps } from '_core/helpers/metadata'
import SwipeDownPageTransition from '_core/components/atoms/pageTransitions/SwipeDownPageTransition'

import AppBar from './AppBar'
import Sidebar from './Sidebar'
import AppBarSpacer from 'account/components/atoms/spacing/AppBarSpacer'

interface Props {
  children: React.ReactNode
  slug: string
  disableContentContainer?: boolean
  metadata: CustomSeoTagsProps
}

const Content = ({ children }: { children: React.ReactNode }) => (
  <>
    <AppBarSpacer sx={{ display: { xs: 'hidden', lg: 'block' } }} />
    <Box pb={7} width={1}>
      {children}
    </Box>
  </>
)

const Layout = ({
  children,
  slug,
  disableContentContainer,
  metadata,
}: Props) => {
  const { state: loadingState } = useLoading()
  const [mobileSidebar, setMobileSidebar] = useState(false)

  const hideMobileSidebar = () => {
    setMobileSidebar(false)
  }

  const showMobileSidebar = () => {
    setMobileSidebar(true)
  }
  const customTags = customSeoTags({ ...metadata })

  return (
    <>
      <Head
        pageTitle={customTags && customTags.title ? customTags.title : ''}
      />
      <HelmetDatoCms seo={customTags} />
      <SwipeDownPageTransition />
      {(loadingState.loading || loadingState.uploading) && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            zIndex: 99999999,
          }}
        >
          <LinearProgress
            sx={{
              backgroundColor: 'rgb(0,0,0,0.1) !important',
            }}
          />
        </Box>
      )}

      <Flex fullWidth>
        <AppBar showMobileSidebar={showMobileSidebar} />
        <Sidebar
          mobileSidebar={mobileSidebar}
          hideMobileSidebar={hideMobileSidebar}
          slug={slug}
        />
        <Box
          sx={{
            flexGrow: 1,
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          <Section
            bgcolor="lightGrey"
            sx={{
              minHeight: '100vh',
              width: '100%',
              fontSize: '1.6rem',
            }}
          >
            {disableContentContainer ? (
              <Content>{children}</Content>
            ) : (
              <Container maxWidth={false}>
                <Content>{children}</Content>
              </Container>
            )}
          </Section>
        </Box>
      </Flex>
      <StatusMessage />
    </>
  )
}

export default Layout
