import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Container, Skeleton } from '@mui/material'

import { useLoading } from '_core/context/LoadingContext'
import { useAccount } from 'account/context/AccountContext'
import Layout from 'account/components/organisms/global/Layout'
import ProfileForm from 'account/components/organisms/employmentProfile/form/ProfileForm'
import ProfileWizard from 'account/components/organisms/employmentProfile/wizard/ProfileWizard'
import profileFields from 'account/helpers/profileFields'

import SectionHeading from 'public/components/molecules/sections/SectionHeading'

export type allTermsDataProps = {
  departments: any[]
  regions: any[]
  levels: any[]
  locations: any[]
  products: any[]
  sectors: any[]
}

const EmploymentProfile = () => {
  const { state: accountState } = useAccount()
  const { state: loadingState } = useLoading()

  const data = useStaticQuery<Queries.EmploymentProfileQuery>(graphql`
    query EmploymentProfile {
      datoCmsAppAccountPage(originalId: { eq: "32377965" }) {
        ...accountPage
      }
      allDatoCmsAppProfileField {
        edges {
          node {
            ...ProfileField
          }
        }
      }
    }
  `)

  const page = data.datoCmsAppAccountPage
  const formikData = profileFields({
    cmsProfileFields: data.allDatoCmsAppProfileField.edges,
  })

  return (
    <Layout
      slug={page.slug}
      metadata={{
        seoTags: page.seoMetaTags,
      }}
    >
      <SectionHeading
        heading={page.title}
        boxProps={{
          mt: 8,
        }}
      />

      {accountState.fetched.profile ? (
        <>
          {accountState && accountState.profile ? (
            <ProfileForm validation={formikData.validation} />
          ) : (
            <ProfileWizard
              initialValues={formikData.initialValues}
              validation={formikData.validation}
            />
          )}
        </>
      ) : (
        <Container maxWidth={false}>
          <Skeleton variant="rectangular" height={800} animation="wave" />
        </Container>
      )}
    </Layout>
  )
}

export default EmploymentProfile
