import React from 'react'
import { Router } from '@reach/router'

import PrivateRoute from 'account/helpers/PrivateRoute'

import SignUp from './SignUp'
import SignIn from './SignIn'
import Dashboard from './Dashboard'
import FavouriteMandates from './FavouriteMandates'
import MandateApplications from './MandateApplications'
import MarketUpdates from './MarketUpdates'
import RelevantMandates from './RelevantMandates'
import EmploymentProfile from './EmploymentProfile'
import Interests from './Interests'
import AccountSettings from './AccountSettings'

const AccountRoutes = () => {
  return (
    <Router>
      <PrivateRoute path="/account/dashboard" component={<Dashboard />} />
      <PrivateRoute
        path="/account/favourite-mandates"
        component={<FavouriteMandates />}
      />
      <PrivateRoute
        path="/account/mandate-applications"
        component={<MandateApplications />}
      />
      <PrivateRoute
        path="/account/relevant-mandates"
        component={<RelevantMandates />}
      />
      <PrivateRoute
        path="/account/market-updates"
        component={<MarketUpdates />}
      />
      <PrivateRoute path="/account/interests" component={<Interests />} />
      <PrivateRoute path="/account/settings" component={<AccountSettings />} />
      <PrivateRoute
        path="/account/employment-profile"
        component={<EmploymentProfile />}
      />
      <SignUp path="/account/sign-up" />
      <SignIn path="/account/sign-in" />
    </Router>
  )
}

export default AccountRoutes
