import React, { memo } from 'react'
import { Box, BoxProps, styled } from '@mui/material'

import { toolbar } from 'account/styles/toolbar'

const Spacer = styled(Box)(({ theme }) => ({
  ...toolbar(),
}))

const AppBarSpacer = (props: BoxProps) => {
  return <Spacer {...props} />
}

export default memo(AppBarSpacer)
