import React, { memo } from 'react'
import { Box } from '@mui/material'
import { Swiper } from 'swiper/react'
import SwiperCore, { Autoplay } from 'swiper'
import 'swiper/css'

interface Props {
  children: React.ReactNode
}

const ProgressSwiper = ({ children }: Props) => {
  SwiperCore.use([Autoplay])

  return (
    <Box width="100%">
      <Swiper
        slidesPerView={1}
        spaceBetween={0}
        loop={true}
        autoplay={{
          delay: 4000,
          disableOnInteraction: true,
        }}
        observer={true}
        observeParents={true}
      >
        {children}
      </Swiper>
    </Box>
  )
}

export default memo(ProgressSwiper)
