import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Box, Container, Skeleton } from '@mui/material'
import { Form, Formik } from 'formik'
import { object, string, boolean } from 'yup'
// core
import SaveButton from '_core/components/atoms/buttons/SaveButton'
import FormikCheckbox from '_core/components/atoms/inputs/formik/FormikCheckbox'
import AvatarImage from 'account/components/molecules/editRow/AvatarImage'
import FormikSelect from '_core/components/atoms/inputs/formik/FormikSelect'
import FormikTextField from '_core/components/atoms/inputs/formik/FormikTextField'
import Flex from '_core/components/atoms/layout/Flex'
import Panel from '_core/components/molecules/panels/Panel'

// account
import Avatar from 'account/components/atoms/profile/Avatar'
import ChangePassword from 'account/components/molecules/editRow/ChangePassword'
import Layout from 'account/components/organisms/global/Layout'
import { useAccount } from 'account/context/AccountContext'
// public
import SectionHeading from 'public/components/molecules/sections/SectionHeading'

type formValues = {
  picture: string
  title: string | undefined
  firstName: string
  lastName: string
  email: string
  currentPassword: string
  newPassword: string
  confirmNewPassword: string
  notifications: boolean
  mailingList: boolean
  privacyPolicy: boolean
}

type title = {
  name: string
  value: string
}

const AccountSettings = () => {
  const { state: accountState, update } = useAccount()
  const [drawerOpen, setDrawerOpen] = useState(false)

  const handleSubmit = (values: formValues) => {
    let dataObj = {
      account: {
        title: values.title,
        firstName: values.firstName,
        lastName: values.lastName,
        notifications: values.notifications,
        mailingList: values.mailingList,
        picture:
          accountState && accountState.account.picture
            ? accountState.account.picture
            : '',
        privacyPolicy:
          accountState && accountState.account.privacyPolicy
            ? accountState.account.privacyPolicy
            : true,
      },
    }
    update({ type: 'account', variables: dataObj })
  }

  let initialValues: formValues = {
    picture:
      accountState && accountState.account && accountState.account.picture
        ? accountState.account.picture
        : '',
    title:
      accountState && accountState.account && accountState.account.title
        ? accountState.account.title
        : '',
    firstName:
      accountState && accountState.account && accountState.account.firstName
        ? accountState.account.firstName
        : '',
    lastName:
      accountState && accountState.account && accountState.account.lastName
        ? accountState.account.lastName
        : '',
    email:
      accountState && accountState.account && accountState.account.email
        ? accountState.account.email
        : '',
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
    notifications:
      accountState && accountState.account && accountState.account.notifications
        ? accountState.account.notifications
        : false,
    mailingList:
      accountState && accountState.account && accountState.account.mailingList
        ? accountState.account.mailingList
        : false,
    privacyPolicy:
      accountState && accountState.account && accountState.account.privacyPolicy
        ? accountState.account.privacyPolicy
        : true,
  }

  const validationSchema = {
    title: string().required('Please select a title'),
    firstName: string().required('Please enter your first name'),
    lastName: string().required('Please enter your last name'),
    privacyPolicy: boolean().required('You must agree to the privacy policy'),
  }

  const marginTop = 3

  const data = useStaticQuery<Queries.AccountSettingsQuery>(graphql`
    query AccountSettings {
      datoCmsAppAccountPage(originalId: { eq: "32378044" }) {
        ...accountPage
      }
      allDatoCmsAppSalutation {
        edges {
          node {
            label
            originalId
          }
        }
      }
    }
  `)
  const page = data.datoCmsAppAccountPage

  let titles: title[] = []

  data.allDatoCmsAppSalutation.edges.map((item: any) => {
    const salutation: Queries.DatoCmsAppSalutation = item.node
    titles.push({ name: salutation.label, value: salutation.label })
  })

  return (
    <>
      <Layout
        slug={page?.slug}
        metadata={{
          seoTags: page?.seoMetaTags,
        }}
      >
        <SectionHeading
          heading={page?.title}
          boxProps={{
            mt: 8,
          }}
        />

        {accountState.fetched.account ? (
          <>
            <Panel
              headerProps={{
                heading: 'Profile Picture',
                useSmallHeading: true,
              }}
            >
              <Box px={{ xs: 2, md: 4, lg: 6 }} py={{ xs: 3, md: 4, lg: 6 }}>
                <Container maxWidth="lg" style={{ padding: 0 }}>
                  <Flex
                    fullWidth
                    alignCenter
                    sx={{ flexDirection: { xs: 'column', sm: 'row' } }}
                  >
                    <Avatar
                      sizeVariant="xl"
                      typography="h3"
                      withBorder
                      flexShrink={0}
                    />
                    <Box ml={2} mt={{ xs: 2, sm: 0 }}>
                      <AvatarImage
                        name="avatarPicture"
                        id="avatarPicture"
                        label={`${
                          accountState &&
                          accountState.account &&
                          accountState.account.picture
                            ? 'Replace'
                            : 'Upload'
                        } picture...`}
                        accept="image/x-png,image/gif,image/jpeg"
                        helperText="The image must be in either a jpeg, png or gif format"
                      />
                    </Box>
                  </Flex>
                </Container>
              </Box>
            </Panel>
            <Formik
              initialValues={initialValues}
              validationSchema={object().shape(validationSchema)}
              onSubmit={(values) => {
                handleSubmit(values)
              }}
            >
              <Form>
                <Panel
                  headerProps={{
                    heading: 'Personal Information',
                    useSmallHeading: true,
                  }}
                >
                  <Box
                    px={{ xs: 2, md: 4, lg: 6 }}
                    py={{ xs: 3, md: 4, lg: 6 }}
                    pb={{ lg: 9 }}
                  >
                    <Container maxWidth="lg" style={{ padding: 0 }}>
                      <FormikSelect
                        name="title"
                        items={titles}
                        placeholder="Title..."
                        hiddenLabel={false}
                      />
                      <FormikTextField
                        name="firstName"
                        label="First Name"
                        placeholder="First Name"
                        sx={{ mt: marginTop }}
                        grey
                        fullWidth
                      />
                      <FormikTextField
                        name="lastName"
                        label="Last Name"
                        placeholder="Last Name"
                        sx={{ mt: marginTop }}
                        grey
                        fullWidth
                      />
                    </Container>
                  </Box>
                </Panel>
                <Panel
                  headerProps={{
                    heading: 'Account Information',
                    useSmallHeading: true,
                  }}
                >
                  <Box
                    px={{ xs: 2, md: 4, lg: 6 }}
                    py={{ xs: 3, md: 4, lg: 5 }}
                    pb={{ lg: 9 }}
                  >
                    <Container maxWidth="lg" style={{ padding: 0 }}>
                      <FormikTextField
                        name="email"
                        label="Email Address"
                        placeholder="Email Address"
                        type="email"
                        sx={{ mt: marginTop }}
                        disabled
                        grey
                        fullWidth
                      />
                      <ChangePassword />
                    </Container>
                  </Box>
                </Panel>
                <Panel
                  headerProps={{
                    heading: 'Communication preferences',
                    useSmallHeading: true,
                  }}
                >
                  <Box p={{ xs: 2, md: 4, lg: 6 }}>
                    <Container maxWidth="lg" style={{ padding: 0 }}>
                      <FormikCheckbox
                        name="mailingList"
                        label="Subscribe to the Delta mailing list"
                        sx={{ mt: marginTop }}
                      />
                    </Container>
                  </Box>
                </Panel>
                <Panel
                  headerProps={{
                    heading: 'Privacy Policy Agreement',
                    useSmallHeading: true,
                  }}
                >
                  <Box p={{ xs: 2, md: 4, lg: 6 }}>
                    <Container maxWidth="lg" style={{ padding: 0 }}>
                      <FormikCheckbox
                        name="privacyPolicy"
                        label="I agree to Delta Executive Search's Privacy Policy"
                        sx={{ mt: marginTop }}
                        checkboxProps={{ disabled: true }}
                      />
                    </Container>
                  </Box>
                </Panel>

                <SaveButton />
              </Form>
            </Formik>
          </>
        ) : (
          <>
            {Array.from(Array(3)).map((_, index: number) => {
              return (
                <Skeleton
                  key={`panel-skeleton-${index}`}
                  variant="rectangular"
                  height={400}
                  animation="wave"
                  sx={{ mt: 2 }}
                />
              )
            })}
          </>
        )}
      </Layout>
    </>
  )
}

export default AccountSettings
