import {
  object,
  number,
  NumberSchema,
  string,
  StringSchema,
  array,
  ArraySchema,
  mixed,
  AnySchema,
} from 'yup'
import { Base64 } from 'js-base64'

import { useAccount } from 'account/context/AccountContext'
import {
  profile,
  language,
  languageValue,
} from 'account/context/accountReducer'
import cvFormats from 'account/constants/cv-formats'

import { allTerms } from '_core/constants/term-structure'
import { blankLanguage } from 'account/constants/languages'

type term =
  | Queries.DatoCmsTermDepartment
  | Queries.DatoCmsTermRegion
  | Queries.DatoCmsTermLevel
  | Queries.DatoCmsTermLocation
  | Queries.DatoCmsTermProduct
  | Queries.DatoCmsTermSector
  | Queries.DatoCmsTermLanguage

interface getInitialTermsProps {
  category: Omit<allTerms, 'responsibilities'>
  profile: profile | undefined
}

const getInitialTerms = ({ category, profile }: getInitialTermsProps) => {
  let terms: string[] = []
  if (
    profile &&
    profile[category as keyof typeof profile] &&
    Array.isArray(profile[category as keyof typeof profile])
  ) {
    profile[category as keyof typeof profile].map((term: term) => {
      terms.push(Base64.encode(JSON.stringify(term)))
    })
  }
  if (terms.length > 0) {
    return terms
  }
  return terms
}

interface ProfileFieldsProps {
  profileType?: 'wizard' | 'profile'
}

interface Props {
  cmsProfileFields: Queries.DatoCmsAppProfileField[]
}

export type profileValues = {
  yearsOfExperience: number
  departments: string[]
  regions: string[]
  levels: string[]
  locations: string[]
  products: string[]
  sectors: string[]
  languages: language[]
  employer: string
  jobTitle: string
  linkedIn: string
  mobileNumber: string
  cv: File
}

export type initialValueType =
  | number
  | string
  | term[]
  | string[]
  | language[]
  | languageValue[]
  | undefined

export type initialValues = {
  [key: string]: initialValueType
}
export type validation = {
  [key: string]: NumberSchema | StringSchema | ArraySchema<AnySchema>
}

const profileFields = ({ cmsProfileFields }: Props) => {
  const { state } = useAccount()
  const profile = state.profile

  let fields: any = {}

  let initialValuesObj: initialValues = {}
  let validationObj: validation = {}
  cmsProfileFields?.map((item: any) => {
    const cmsField: Queries.DatoCmsAppProfileField = item.node

    let validation: any = null
    switch (cmsField.fieldId) {
      case 'yearsOfExperience':
        initialValuesObj[cmsField.fieldId] =
          profile && profile[cmsField.fieldId] ? profile[cmsField.fieldId] : 0
        profile && profile[cmsField.fieldId] ? profile[cmsField.fieldId] : 0
        if (cmsField.validation && cmsField?.validation[0]?.message) {
          validationObj[cmsField.fieldId] = number().min(
            1,
            cmsField?.validation[0]?.message
          )
        }
        break
      case 'departments':
      case 'regions':
      case 'levels':
      case 'locations':
        initialValuesObj[cmsField.fieldId] = getInitialTerms({
          category: cmsField.fieldId,
          profile: profile,
        })
        if (cmsField.validation && cmsField?.validation[0]?.message) {
          validationObj[cmsField.fieldId] = array().min(
            1,
            cmsField?.validation[0]?.message
          )
        }
        break
      case 'products':
      case 'sectors':
        initialValuesObj[cmsField.fieldId] = getInitialTerms({
          category: cmsField.fieldId,
          profile: profile,
        })
        break
      case 'languages':
        initialValuesObj[cmsField.fieldId] =
          profile && profile.languages
            ? profile.languages
            : [{ ...blankLanguage }]

        let langMinMessage: string = ''
        let langProficiencyMessage: string = ''
        let langRequiredMessage: string = ''
        if (cmsField.validation && cmsField.validation.length > 0) {
          cmsField.validation.map(
            (validationItem: Queries.DatoCmsAppValidationType) => {
              switch (validationItem.validationType) {
                case 'min':
                  langMinMessage = validationItem.message
                  break
                case 'proficiency':
                  langProficiencyMessage = validationItem.message
                  break
                case 'required':
                  langRequiredMessage = validationItem.message
                  break
              }
            }
          )
        }

        validationObj[cmsField.fieldId] = array()
          .min(1, langMinMessage)
          .of(
            object().shape({
              proficiency: string().required(langProficiencyMessage),
              language: string().required(langRequiredMessage),
            })
          )
        break
      case 'employer':
      case 'jobTitle':
        initialValuesObj[cmsField.fieldId] =
          profile && profile.currentEmployer[cmsField.fieldId]
            ? profile.currentEmployer[cmsField.fieldId]
            : ''
        if (cmsField.validation && cmsField?.validation[0]?.message) {
          validationObj[cmsField.fieldId] = string().required(
            cmsField?.validation[0]?.message
          )
        }
        break
      case 'cv':
        initialValuesObj[cmsField.fieldId] =
          profile && profile.cv ? profile.cv : ''

        let cvRequiredMessage: string = ''
        let cvFormatMessage: string = ''
        let cvSizeMessage: string = ''
        if (cmsField.validation && cmsField.validation.length > 0) {
          cmsField.validation.map(
            (validationItem: Queries.DatoCmsAppValidationType) => {
              switch (validationItem.validationType) {
                case 'required':
                  cvRequiredMessage = validationItem.message
                  break
                case 'format':
                  cvFormatMessage = validationItem.message
                  break
                case 'size':
                  cvSizeMessage = validationItem.message
                  break
              }
            }
          )
        }
        validationObj[cmsField.fieldId] = mixed()
          .required(cvRequiredMessage)
          .test(
            'fileFormat',
            cvFormatMessage,
            (value) => value && cvFormats.includes(value.type)
          )
          .test(
            'fileSize',
            cvSizeMessage,
            (value) => value && value.size <= 8388608 //8mb
          )
        break
      case 'linkedIn':
        initialValuesObj[cmsField.fieldId] =
          profile && profile.linkedIn ? profile.linkedIn : ''
        break
      case 'mobileNumber':
        initialValuesObj[cmsField.fieldId] =
          profile &&
          profile.contactInformation &&
          profile.contactInformation.mobileNumber
            ? profile.contactInformation.mobileNumber
            : ''
        if (cmsField.validation && cmsField?.validation[0]?.message) {
          validationObj[cmsField.fieldId] = string().required(
            cmsField?.validation[0]?.message
          )
        }
        // .matches(regexPhoneNumber, 'Phone number is not valid'),
        break
    }
  })

  return { validation: validationObj, initialValues: initialValuesObj }
}

export default profileFields
