import React, { memo } from 'react'
import { Box, styled } from '@mui/material'

import Flex from '_core/components/atoms/layout/Flex'

import FormikTextField from './FormikTextField'
import FormikSelect, { FormikSelectProps } from './FormikSelect'
import CloseButton from '_core/components/atoms/buttons/CloseButton'

import { SelectItemProps } from '../Select'

interface WrapperProps {
  hasError?: boolean
  isValid?: boolean
}

interface Props extends WrapperProps {
  textName: string
  selectName: string
  textPlaceholder: string
  selectPlaceholder: string
  selectItems: SelectItemProps[]
  selectValue?: string
  selectProps: Omit<
    FormikSelectProps,
    'name' | 'items' | 'label' | 'placeholder'
  >
  handleRemove: React.MouseEventHandler<HTMLButtonElement>
  index: number
}

const Wrapper = styled(Flex, {
  shouldForwardProp: (prop) => prop !== 'hasError' && prop !== 'isValid',
})<WrapperProps>(({ hasError, isValid, theme }) => ({
  background: theme.palette.lightGrey,
  borderRadius: theme?.shape?.borderRadius,
  overflow: 'hidden',
  border: `1px solid rgba(255, 255, 255, 0)`,
  transition: theme.transitions.create(['border-color']),
  ['&:focus-within']: {
    border: `1px solid ${theme.palette.outlineDark}`,
  },
  ['.MuiFormControl-root']: {
    height: '100%',
    border: 'none',
    background: theme.palette.lightGrey,
  },
  ['.MuiOutlinedInput-notchedOutline']: {
    border: 'none !important',
  },
  ...(hasError && {
    borderColor: theme.palette.error.main,
  }),
  ...(isValid && {
    borderColor: theme.palette.success.main,
  }),
}))

const StyledTextField = styled(FormikTextField)(({ theme }) => ({
  flex: 1,
  borderRight: `1px solid ${theme.palette.outlineDark} !important`,
  ['.MuiInput-root']: {
    ['.MuiInput-input']: {
      background: 'none',
      // border: 'none',
    },
  },
}))

const StyledSelect = styled(FormikSelect)(({ theme }) => ({
  height: '100%',
  ['&, .MuiInputBase-root']: {
    height: '100%',
    // border: 'none',
    background: 'none',
  },
  ['.MuiSelect-select']: {
    background: 'none',
  },
  ['.MuiSelect-root']: {
    height: '100%',
  },
  ['label + .MuiInput-formControl']: {
    marginTop: 0,
  },
}))

const FormikTextSelectField = ({
  textName,
  selectName,
  textPlaceholder,
  selectPlaceholder,
  selectItems,
  selectValue,
  selectProps,
  handleRemove,
  index,
  hasError,
  isValid,
}: Props) => {
  return (
    <>
      <Flex alignCenter fullWidth>
        <Box flex={1}>
          <Wrapper fullWidth hasError={hasError} isValid={isValid}>
            <StyledTextField
              name={textName}
              label={textPlaceholder}
              placeholder={textPlaceholder}
              hiddenLabel
              hideError
              grey
              sx={{ mb: 0 }}
            />

            <Box id={selectName} sx={{ width: { xs: '40%', xl: '30%' } }}>
              <StyledSelect
                name={selectName}
                items={selectItems}
                placeholder={selectPlaceholder}
                fullWidth
                hiddenLabel
                value={selectValue ? selectValue : ''}
                MenuProps={{
                  container: () => document.getElementById(selectName),
                }}
                {...selectProps}
                sx={{ height: '100%' }}
              />
            </Box>
          </Wrapper>
        </Box>
        <Box ml={1}>
          <CloseButton
            onClick={handleRemove}
            round
            withoutLabel
            sizeVariant="lg"
            disabled={index == 0 ? true : false}
          />
        </Box>
      </Flex>
    </>
  )
}

export default memo(FormikTextSelectField)
