import React from 'react'
import { AppBar, Container, Toolbar, styled } from '@mui/material'

import InternalLink from '_core/components/atoms/links/InternalLink'

import DeltaLogo from '_core/components/atoms/logos/DeltaLogo'
import Flex from '_core/components/atoms/layout/Flex'
import MenuButton from '_core/components/atoms/buttons/MenuButton'
import { headerCollapse } from '_core/styles/header-collapse'

import { toolbar } from 'account/styles/toolbar'
import AccountMenu from 'account/components/molecules/header/AccountMenu'

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  boxSizing: 'border-box',
  backgroundColor: theme.palette.secondary.main,
  zIndex: theme.zIndex.drawer + 1,
  boxShadow: theme.palette.shadows.light,
  [theme.breakpoints.up('xl')]: {
    backgroundColor: 'white !important',
  },
}))

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  ...toolbar(),
  alignItems: 'center',
  fontSize: '1.6rem',
  color: 'white',
  [theme.breakpoints.up('xl')]: {
    color: theme.palette.textDark,
  },
  [theme.breakpoints.down('md')]: {
    ...headerCollapse,
  },
}))

const LogoContainer = styled(Flex)(({ theme }) => ({
  alignSelf: 'stretch',
  alignItems: 'center',
  height: 'auto',
  justifyContent: 'center',
  [theme.breakpoints.up('xl')]: {
    width: theme.appbarDrawerWidth,
    backgroundColor: theme.palette.secondary.main,
  },
}))

interface Props {
  showMobileSidebar: React.MouseEventHandler<HTMLButtonElement>
}

const SidebarNav = ({ showMobileSidebar }: Props) => {
  return (
    <>
      <StyledAppBar position="fixed">
        <StyledToolbar disableGutters>
          <Flex sx={{ display: { xs: 'flex', xl: 'none !important' }, flex: 1 }} alignCenter>
            <Container>
              <MenuButton onClick={showMobileSidebar} />
            </Container>
          </Flex>

          <LogoContainer fullHeight>
            <InternalLink slug="" aria-label="Home">
              <DeltaLogo style={{ width: '155px' }} />
            </InternalLink>
          </LogoContainer>
          <Flex flex={1} justifyContent="flex-end" ml="auto">
            <Container sx={{ width: 'auto', mx: 0 }}>
              <AccountMenu />
            </Container>
          </Flex>
        </StyledToolbar>
      </StyledAppBar>
    </>
  )
}

export default SidebarNav
