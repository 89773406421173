// npm
import React, { useEffect, useState } from 'react'
import { Bookmark } from '@mui/icons-material'
//core
import InternalButtonLink from '_core/components/atoms/buttons/InternalButtonLink'
import PanelFooter from '_core/components/molecules/panels/PanelFooter'
//account
import ActionRequired from 'account/components/molecules/messaging/ActionRequired'
import MandateSummaryPanel from 'account/components/organisms/dashboard/MandateSummaryPanel'
import { useAccount } from 'account/context/AccountContext'
import { mandateId } from 'account/context/accountReducer'

const footerButtons = [
  {
    slug: 'account/favourite-mandates',
    label: 'View All Favourite Mandates',
  },
]

const FavouriteMandatesPanel = () => {
  const { state: accountState } = useAccount()
  const [mandateIds, setMandateIds] = useState<string[] | undefined>(undefined)

  useEffect(() => {
    if (
      accountState.favoriteMandate &&
      accountState.favoriteMandate.length > 0
    ) {
      let ids: string[] = []
      accountState.favoriteMandate.map((item: mandateId) => {
        ids.push(item.id)
      })
      setMandateIds(ids)
    }
  }, [accountState.favoriteMandate])

  return (
    <MandateSummaryPanel
      panelHeading="My Favourite Mandates"
      panelHeadingIcon={<Bookmark />}
      mandateIds={mandateIds}
      footerButtons={footerButtons}
      actionRequired={
        <>
          <ActionRequired>
            You have not yet added any favourite mandates.
          </ActionRequired>
          <PanelFooter py={{ xs: 2 }}>
            <InternalButtonLink
              slug="mandates"
              variant="contained"
              color="primary"
              sizeVariant="lg"
              sx={{ ml: 2 }}
            >
              Browse Mandates
            </InternalButtonLink>
          </PanelFooter>
        </>
      }
    />
  )
}

export default FavouriteMandatesPanel
