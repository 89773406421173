import React from 'react'

import Panel from '_core/components/molecules/panels/Panel'
import TermButtonQueryGroup from '_core/components/molecules/buttonGroups/TermButtonQueryGroup'
import termStructure, { termProps } from '_core/constants/term-structure'

import { useAccount } from 'account/context/AccountContext'
import { validation } from 'account/helpers/profileFields'
import EditRowWrapper from 'account/components/molecules/editRow/EditRowWrapper'
import TermButtonsInput from 'account/components/organisms/employmentProfile/steps/TermButtons'

interface Props {
  validation: validation
}

const InterestsForm = ({ validation }: Props) => {
  const { state } = useAccount()

  return (
    <Panel>
      {Object.keys(termStructure.primary).map((key: string, index: number) => {
        const term: termProps =
          termStructure.primary[key as keyof typeof termStructure.primary]

        return (
          <EditRowWrapper
            key={`edit-interests-row-${key}-${index}`}
            type="interests"
            drawerHeading={term.name}
            fields={[
              {
                name: term.name,
                fieldId: key,
                validation: validation[key],
                value: state?.interests[key as keyof typeof state.interests],
                buttonLayout: true,
                isTerms: true,
                isEmpty:
                  state?.interests[key as keyof typeof state.interests] ===
                    null ||
                  state?.interests[key as keyof typeof state.interests]
                    .length === 0
                    ? true
                    : false,
                displayComponent: (
                  <TermButtonQueryGroup
                    keyId={key}
                    // @ts-ignore
                    category={key}
                    filterTerms={
                      state?.interests[key as keyof typeof state.interests]
                    }
                    termButtonProps={{
                      size: 'xl',
                    }}
                  />
                ),
                drawerComponent: <TermButtonsInput termCategory={key} />,
              },
            ]}
          />
        )
      })}
    </Panel>
  )
}

export default InterestsForm
