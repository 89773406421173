import React, { useState } from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { Form, Formik } from 'formik'
import { Box, styled } from '@mui/material'
import { Check, ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'

import Flex from '_core/components/atoms/layout/Flex'
import Button from '_core/components/atoms/buttons/Button'

const Wrapper = styled('div')({
  width: '100%',
  overflow: 'hidden',

  ['.wizard-container']: {
    position: 'relative',
    overflow: 'hidden',
    width: '200%',
    height: '100%',
  },
  ['.wizard-step']: {
    position: 'relative',
    width: '50%',
    float: 'left',
  },
  ['.wizard-enter']: {
    transform: 'translateX(100%)',
    opacity: 0,
    ['&.wizard-enter-active']: {
      opacity: 1,
      transform: 'translateX(0)',
      transition: 'all 500ms ease',
    },
  },
  ['.wizard-exit']: {
    opacity: 1,
    transform: 'translateX(-100%)',
    ['&.wizard-exit-active']: {
      opacity: 0,
      transform: 'translateX(-200%)',
      transition: 'all 500ms ease',
    },
  },
  ['.back']: {
    ['.wizard-step']: {
      float: 'right',
    },
    ['.wizard-enter']: {
      transform: 'translateX(-200%)',
      ['&.wizard-enter-active']: {
        transform: 'translateX(-100%)',
      },
    },
    ['.wizard-enter-done']: {
      float: 'left',
    },
    ['.wizard-exit']: {
      transform: 'translateX(0)',
      ['&.wizard-exit-active']: {
        transform: 'translateX(100%)',
      },
    },
  },
})

const StyledButton = styled(Button)(({ theme }) => ({
  minWidth: '160px',
  ['svg']: {
    fontSize: '1.2rem !important',
  },
}))

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

const WizardWrapper = ({ children, initialValues, onSubmit }) => {
  const [stepNumber, setStepNumber] = useState(0)

  const steps = React.Children.toArray(children)
  const [snapshot, setSnapshot] = useState(initialValues)
  const [forward, setForward] = useState(true)

  const step = steps[stepNumber]
  const totalSteps = steps.length
  const isLastStep = stepNumber === totalSteps - 1

  const next = (values) => {
    setSnapshot(values)
    setStepNumber(Math.min(stepNumber + 1, totalSteps - 1))
    setForward(true)
  }

  const previous = (values) => {
    setSnapshot(values)
    setStepNumber(Math.max(stepNumber - 1, 0))
    setForward(false)
  }

  const handleSubmit = async (values, bag) => {
    if (step.props.onSubmit) {
      await step.props.onSubmit(values, bag)
    }

    setForward(true)

    if (isLastStep) {
      return onSubmit(values, bag)
    } else {
      bag.setTouched({})
      next(values)
    }
  }

  return (
    <Wrapper>
      <Formik
        initialValues={snapshot}
        onSubmit={handleSubmit}
        validationSchema={step.props.validationSchema}
      >
        {(formik) => {
          return (
            <Form>
              <Flex flexDirection="column" style={{ height: '100%' }}>
                <Box mb="auto">
                  <div className={forward ? 'forward' : 'back'}>
                    <TransitionGroup className="wizard-container">
                      <CSSTransition
                        key={step.props.stepId}
                        classNames="wizard"
                        timeout={{ enter: 800, exit: 800 }}
                      >
                        <div className="wizard-step">
                          {step}

                          <Flex center fullWidth mt={4}>
                            <StyledButton
                              variant="contained"
                              color="primary"
                              sizeVariant="xl"
                              onClick={() => previous(formik.values)}
                              disabled={stepNumber > 0 ? false : true}
                              sx={{ mr: 1 }}
                              startIcon={<ArrowBackIos />}
                            >
                              Previous
                            </StyledButton>
                            <StyledButton
                              variant="contained"
                              color="primary"
                              sizeVariant="xl"
                              sx={{ mr: 1 }}
                              type="submit"
                              disabled={formik.isSubmitting}
                              endIcon={
                                isLastStep ? <Check /> : <ArrowForwardIos />
                              }
                            >
                              {isLastStep ? <>Save</> : <>Next</>}
                            </StyledButton>
                          </Flex>
                        </div>
                      </CSSTransition>
                    </TransitionGroup>
                  </div>
                </Box>
              </Flex>
            </Form>
          )
        }}
      </Formik>
    </Wrapper>
  )
}

export default WizardWrapper
