import React, { memo } from 'react'

import DataRowErrorWrapper from '_core/components/molecules/panels/DataRowErrorWrapper'
import DataRow from '_core/components/molecules/panels/DataRow'
import FormikPhoneNumber from '_core/components/atoms/inputs/formik/FormikPhoneNumber'

interface Props {
  fieldName: string
}

const Contact = ({ fieldName }: Props) => {
  return (
    <DataRowErrorWrapper fieldName={fieldName}>
      <DataRow
        label="Mobile phone number"
        outerWrapperProps={{ alignItems: 'center' }}
        // labelRightAlign
      >
        <FormikPhoneNumber name={fieldName} omitError />
      </DataRow>
    </DataRowErrorWrapper>
  )
}

export default memo(Contact)
