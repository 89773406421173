import React from 'react'
import { Box, Container, Divider, styled, useTheme, useMediaQuery } from '@mui/material'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from 'account/components/organisms/global/Layout'
import Stats from 'account/components/organisms/dashboard/Stats'
import InterestsPanel from 'account/components/organisms/dashboard/InterestsPanel'
import ProfilePanel from 'account/components/organisms/dashboard/ProfilePanel'
import RelevantMandatesPanel from 'account/components/organisms/dashboard/RelevantMandatesPanel'
import FavoriteMandatesPanel from 'account/components/organisms/dashboard/FavoriteMandatesPanel'
import MandateApplicationsPanel from 'account/components/organisms/dashboard/MandateApplicationsPanel'
import AppBarSpacer from 'account/components/atoms/spacing/AppBarSpacer'
import Profile from 'account/components/molecules/sidebar/Profile'
import Progress from 'account/components/molecules/sidebar/Progress'

// import ApplicationsPanel from 'components/molecules/_account/dashboard/ApplicationsPanel'

const ContentWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  ['&:before']: {
    top: 0,
    display: 'block',
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '100px',
    backgroundColor: theme.palette.textDark,
  },
}))

const Content = () => {
  return (
    <>
      <Stats />
      <RelevantMandatesPanel />
      <FavoriteMandatesPanel />
      <MandateApplicationsPanel />
      <InterestsPanel />
      <ProfilePanel />
    </>
  )
}

const Dashboard = () => {
  const theme = useTheme()
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'))
  const data = useStaticQuery<Queries.DashboardQuery>(graphql`
    query Dashboard {
      datoCmsAppAccountPage(originalId: { eq: "32350634" }) {
        ...accountPage
      }
    }
  `)
  const d = data.datoCmsAppAccountPage
  return (
    <Layout
      slug={d.slug}
      disableContentContainer={lgUp ? false : true}
      metadata={{
        seoTags: d.seoMetaTags,
      }}
    >
      {lgUp ? (
        <Content />
      ) : (
        <>
          <Box bgcolor="textDark">
            <Container maxWidth="md">
              <Profile />
              <Divider sx={{ backgroundColor: 'dividerLight' }} />
              <Progress visibleOnComplete />
            </Container>
          </Box>
          <ContentWrapper>
            <Container maxWidth={false}>
              <Content />
            </Container>
          </ContentWrapper>
        </>
      )}
    </Layout>
  )
}

export default Dashboard
