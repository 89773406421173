import React from 'react'
import { Box, Divider, Drawer, styled, useTheme } from '@mui/material'
import { Scrollbars } from 'react-custom-scrollbars-2'

import Flex from '_core/components/atoms/layout/Flex'
import DrawerLayout from '_core/components/organisms/global/DrawerLayout'

import Profile from 'account/components/molecules/sidebar/Profile'
import Progress from 'account/components/molecules/sidebar/Progress'
import Nav from 'account/components/molecules/sidebar/Nav'

interface Props {
  mobileSidebar?: boolean
  hideMobileSidebar: React.MouseEventHandler<HTMLButtonElement>
  slug: string
}

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  fontSize: '1.6rem',
  width: theme.appbarDrawerWidth,
  flexShrink: 0,
  ['.MuiPaper-root']: {
    width: theme.appbarDrawerWidth,
    background: theme.palette.textDark,
    color: theme.palette.textLight,
    borderRight: 'none !important',
  },
}))

const SidebarNav = ({ mobileSidebar, hideMobileSidebar, slug }: Props) => {
  const theme = useTheme()
  return (
    <>
      <Box display={{ xs: 'block', lg: 'none' }}>
        <StyledDrawer
          variant="temporary"
          open={mobileSidebar}
          onClose={hideMobileSidebar}
          ModalProps={{
            keepMounted: true,
            sx: {
              zIndex: theme.zIndex.drawer + 2,
            },
          }}
        >
          <Scrollbars
            renderThumbVertical={(props: any) => (
              <Box bgcolor="rgba(255,255,255,0.2)" width="6px" borderRadius="3px" {...props} />
            )}
          >
            <DrawerLayout
              closeButtonProps={{
                colorVariant: 'outline-light',
                onClick: hideMobileSidebar,
              }}
              px={{ xs: 3 }}
              py={{ xs: 2, md: 4, lg: 8 }}
            >
              <Flex alignCenter full>
                <Box py={6} px={1}>
                  <Nav currentPageSlug={slug} strong />
                </Box>
              </Flex>
            </DrawerLayout>
          </Scrollbars>
        </StyledDrawer>
      </Box>
      <Box display={{ xs: 'none', lg: 'block' }}>
        <StyledDrawer variant="permanent" open={mobileSidebar}>
          <Box height="100%" mt={{ xs: theme.appbarHeightSmall, md: theme.appbarHeightLarge }}>
            <Scrollbars
              renderThumbVertical={(props: any) => (
                <Box bgcolor="rgba(255,255,255,0.2)" width="6px" borderRadius="3px" {...props} />
              )}
            >
              <Profile />
              <Divider sx={{ backgroundColor: 'dividerLight' }} />
              <Progress visibleOnComplete />
              <Divider sx={{ backgroundColor: 'dividerLight' }} />
              <Box py={4} px={3}>
                <Nav currentPageSlug={slug} />
              </Box>
            </Scrollbars>
          </Box>
        </StyledDrawer>
      </Box>
    </>
  )
}

export default SidebarNav
