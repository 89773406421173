import React, { memo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Box, Container, styled } from '@mui/material'
import InternalLink from '_core/components/atoms/links/InternalLink'

const StyledFooter = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.outlineDark}`,
  textAlign: 'center',
  color: theme.palette.textGrey,
}))

const Nav = styled('ul')(({ theme }) => ({
  listStyle: 'none',
  li: {
    display: 'inline-block',
    a: {
      color: 'inherit',
      ['&:hover']: {
        color: theme.palette.primary.main,
      },
    },
  },
}))

const BottomNav = () => {
  const data = useStaticQuery<Queries.AccountFooterQuery>(graphql`
    query AccountFooter {
      datoCmsAccountSignup {
        footerNavigation {
          ... on DatoCmsBasicPage {
            title
            slug
            originalId
            model {
              apiKey
            }
          }
          ... on DatoCmsLandingPage {
            slug
            originalId
            heroSmallHeading
            model {
              apiKey
            }
          }
        }
      }
    }
  `)
  const navData = data.datoCmsAccountSignup?.footerNavigation

  return (
    <StyledFooter py={4} mt={10}>
      <Container maxWidth="sm">
        <Nav>
          {navData?.map(
            (
              item: Queries.DatoCmsBasicPage | Queries.DatoCmsLandingPage,
              index
            ) => {
              return (
                <li key={`footer-${item.oiginalId}-${index}`}>
                  <InternalLink slug={item.slug} apiKey={item.model.apiKey}>
                    {item.title ? item.title : ''}
                    {item.heroSmallHeading ? item.heroSmallHeading : ''}
                  </InternalLink>
                  {index + 1 !== navData.length && <>&nbsp;•&nbsp;</>}
                </li>
              )
            }
          )}
        </Nav>
      </Container>
    </StyledFooter>
  )
}

export default memo(BottomNav)
