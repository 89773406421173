import React, { memo } from 'react'
import { Box } from '@mui/material'
import { Field, FieldProps, useFormikContext } from 'formik'

import Slider, { SliderProps } from '../Slider'
import ErrorMessage from '../ErrorMessage'

interface Props extends SliderProps {
  name: string
  hideError?: true
}

const FormikSlider = ({ name, hideError, ...props }: Props) => {
  const { setFieldValue } = useFormikContext()
  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => {
        let hasError = false
        if (meta.touched && meta.error) {
          hasError = true
        }

        return (
          <>
            <input type="hidden" {...field} />
            {!hideError && (
              <ErrorMessage collapsed={hasError} mb={2}>
                {meta.error}
              </ErrorMessage>
            )}
            <Box px={1} mt={3}>
              <Slider
                {...props}
                name={field.name}
                onChange={(event: Event, newValue: number) => {
                  setFieldValue(name, newValue)
                }}
                value={field.value}
                error={hasError}
              />
            </Box>
          </>
        )
      }}
    </Field>
  )
}

export default memo(FormikSlider)
