import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { useAccount } from 'account/context/AccountContext'
import MandateLanding from './templates/MandateLanding'

const RelevantMandates = () => {
  const { getRelevantMandateIds } = useAccount()
  const [mandateIds, setMandateIds] = useState<string[] | undefined>(undefined)

  const data = useStaticQuery<Queries.RelevantMandatesQuery>(graphql`
    query RelevantMandates {
      datoCmsAppAccountPage(originalId: { eq: "32377923" }) {
        ...accountPage
        requiredMessage
        requiredButton {
          ...appButton
        }
        guidanceText
        guidanceButton {
          ...appButton
        }
      }
    }
  `)

  useEffect(() => {
    getRelevantMandateIds().then((result: any) => {
      if (typeof window !== 'undefined') {
        window.gtag('event', 'view_relevant_mandates', {
          action: 'View Relevant Mandates Page',
        })
      }
      let ids: string[] = []
      result.edges.map((item: any) => {
        ids.push(item.node.id)
      })
      setMandateIds(ids)
    })
  }, [])

  return (
    <MandateLanding
      data={data?.datoCmsAppAccountPage}
      stateType="relevantMandates"
      mandateIds={mandateIds}
    />
  )
}

export default RelevantMandates
