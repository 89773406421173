// npm
import React from 'react'
import { styled, Typography } from '@mui/material'
// core
import PanelFooter, {
  PanelFooterProps,
} from '_core/components/molecules/panels/PanelFooter'
import InternalButtonLink from '_core/components/atoms/buttons/InternalButtonLink'
import Flex from '_core/components/atoms/layout/Flex'

const FooterCol = styled(Flex)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  ['&:not(:first-child)']: {
    borderTop: `1px solid ${theme.palette.outlineDark}`,
  },
  [theme.breakpoints.up('md')]: {
    padding: 0,
    ['&:not(:first-child)']: {
      borderTop: 'none',
      borderLeft: `1px solid ${theme.palette.outlineDark}`,
    },
  },
}))

const FooterButtonLink = styled(InternalButtonLink)(({ theme }) => ({
  ['.MuiButton-label']: {
    color: theme.palette.textAccountSidebar,
  },
  [theme.breakpoints.up('md')]: {
    ['&.md']: {
      height: '60px',
      width: '100%',
      borderRadius: 0,
    },
  },
}))

export type footerButton = {
  slug: string
  label: string
}

interface Props extends Omit<PanelFooterProps, 'children'> {
  footerButtons: footerButton[]
  noBorder?: true
}

const PanelFooterButtons = ({ footerButtons, noBorder, ...props }: Props) => {
  return (
    <PanelFooter noBorder={noBorder} {...props}>
      {footerButtons.map((button: footerButton, index: number) => {
        return (
          <FooterCol
            key={`button-slug-${index}`}
            center
            flex={1}
            width={1}
            sx={{ px: 1 }}
          >
            <FooterButtonLink
              sizeVariant="md"
              colorVariant="contained-light-plus"
              fullWidth
              slug={button.slug}
            >
              <Typography variant="smallText">{button.label}</Typography>
            </FooterButtonLink>
          </FooterCol>
        )
      })}
    </PanelFooter>
  )
}

export default PanelFooterButtons
