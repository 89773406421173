import React, { memo } from 'react'
import { Field, FieldProps } from 'formik'
import Select, { SelectProps, SelectItemProps } from '../Select'
import ErrorMessage from '../ErrorMessage'

export interface FormikSelectProps extends SelectProps {
  name: string
  placeholder: string
  label?: string
  items: SelectItemProps[]
}

const FormikSelect = ({
  name,
  placeholder,
  label,
  items,
  ...props
}: FormikSelectProps) => {
  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => {
        const hasError = meta.touched && meta.error && meta.error.length > 0

        const valid = meta.touched && !meta.error
        const { value, ...fieldRest } = field

        let statusClass = ''

        if (valid) {
          statusClass = 'valid'
        }
        if (hasError) {
          statusClass = 'error'
        }
        let matchedValue = ''
        items.map((item: SelectItemProps) => {
          if (item.value === value) {
            matchedValue = item.value
          }
        })

        return (
          <>
            {hasError && (
              <ErrorMessage collapsed={hasError} mb={1}>
                {meta.error}
              </ErrorMessage>
            )}

            <Select
              placeholder={placeholder}
              className={statusClass}
              label={label}
              error={hasError}
              items={items}
              inputProps={{
                name: field.name,
              }}
              {...fieldRest}
              {...props}
              value={matchedValue}
            />
          </>
        )
      }}
    </Field>
  )
}

export default memo(FormikSelect)
