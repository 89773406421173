import { useEffect } from 'react'
import { useFormikContext, FormikErrors } from 'formik'
// import { getFieldErrorNames } from './helpers'

const getFieldErrorNames = (
  formikErrors: FormikErrors<{ [field: string]: any }>
) => {
  const transformObjectToDotNotation = (
    obj: FormikErrors<{ [field: string]: any }>,
    prefix = '',
    result: string[] = []
  ) => {
    Object.keys(obj).forEach((key) => {
      const value: object | string = obj[key]
      if (!value) return

      const nextKey = prefix ? `${prefix}.${key}` : key
      if (typeof value === 'object') {
        transformObjectToDotNotation(value, nextKey, result)
      } else {
        result.push(nextKey)
      }
    })

    return result
  }

  return transformObjectToDotNotation(formikErrors)
}

export const ScrollToFieldError = () => {
  const { submitCount, isValid, errors } = useFormikContext()

  useEffect(() => {
    if (isValid) return

    const fieldErrorNames = getFieldErrorNames(errors)
    if (fieldErrorNames.length <= 0) return

    const element = document.querySelector(
      `input[name='${fieldErrorNames[0]}']`
    )
    if (!element) return

    // Scroll to first known error into view
    if (typeof window !== 'undefined') {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }

    // Formik doesn't (yet) provide a callback for a client-failed submission,
    // thus why this is implemented through a hook that listens to changes on
    // the submit count.
  }, [submitCount]) // eslint-disable-line react-hooks/exhaustive-deps

  return null
}
