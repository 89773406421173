// npm
import React, { useEffect, useState } from 'react'
import { List } from '@mui/icons-material'
//core
import InternalButtonLink from '_core/components/atoms/buttons/InternalButtonLink'
import PanelFooter from '_core/components/molecules/panels/PanelFooter'
//account
import ActionRequired from 'account/components/molecules/messaging/ActionRequired'
import MandateSummaryPanel from 'account/components/organisms/dashboard/MandateSummaryPanel'
import { useAccount } from 'account/context/AccountContext'
import { mandateId } from 'account/context/accountReducer'

const footerButtons = [
  {
    slug: 'account/mandate-applications',
    label: 'View All Applications',
  },
]

const MandateApplicationsPanel = () => {
  const { state: accountState } = useAccount()
  const [mandateIds, setMandateIds] = useState<string[] | undefined>(undefined)

  useEffect(() => {
    if (accountState.applications && accountState.applications.length > 0) {
      let ids: string[] = []
      accountState.applications.map((item: mandateId) => {
        ids.push(item.id)
      })
      setMandateIds(ids)
    }
  }, [accountState.applications])

  return (
    <MandateSummaryPanel
      panelHeading="Mandates I've Applied For"
      panelHeadingIcon={<List />}
      mandateIds={mandateIds}
      footerButtons={footerButtons}
      actionRequired={
        <>
          <ActionRequired>
            You have not yet applied for any mandates.
          </ActionRequired>
          <PanelFooter py={{ xs: 2 }}>
            <InternalButtonLink
              slug="mandates"
              variant="contained"
              color="primary"
              sizeVariant="lg"
              sx={{ ml: '2' }}
            >
              Browse Mandates
            </InternalButtonLink>
          </PanelFooter>
        </>
      }
    />
  )
}

export default MandateApplicationsPanel
