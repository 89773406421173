import React from 'react'
import { Box, BoxProps, styled } from '@mui/material'

import Flex, { FlexProps } from '_core/components/atoms/layout/Flex'

interface WrapperProps {
  isCollapsed?: true
}
export interface DataRowProps extends WrapperProps {
  label: string
  children: React.ReactNode
  outerWrapperProps?: BoxProps
  flexProps?: FlexProps
  mobileInline?: true
  borderTop?: true
  noPaddingTop?: true
  isTerms?: true
  isButton?: true
  labelProps?: BoxProps
}

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isCollapsed',
})<WrapperProps>(({ isCollapsed, theme }) => ({
  ...(isCollapsed && {
    ['&, &.auto-height, .grid, .grid-item, label']: {
      height: 'auto !important',
      lineHeight: '0 !important',
    },
  }),
}))

const Content = styled(Box)(({ theme }) => ({
  ['&.full-width-btn']: {
    ['.MuiButton-root']: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        minWidth: 'auto',
      },
      [theme.breakpoints.up('sm')]: {
        minWidth: '300px',
      },
    },
  },
}))

const DataRow = ({
  label,
  children,
  mobileInline,
  labelProps,
  outerWrapperProps,
  borderTop,
  noPaddingTop,
  flexProps,
  isTerms,
  isButton,
  isCollapsed,
}: DataRowProps) => {
  let wrapperProps = { ...outerWrapperProps }
  let flexPropsCopy = { ...flexProps, alignItems: 'center' }
  let contentProps = {
    width: {
      xs: '100%',
      md: '70%',
      lg: '70%',
      xl: '80%',
    },
  }

  let labProps = {
    ...labelProps,
    alignSelf: {
      xs: 'flex-start',
      md: 'center',
    },
    width: {
      xs: '100%',
      md: '30%',
      lg: '30%',
      xl: '20%',
    },
    mb: {
      xs: isCollapsed ? 0 : 1,
      md: 0,
    },
  }

  if (mobileInline) {
    contentProps = {
      ml: {
        xs: 'auto',
      },
      mt: {
        xs: '0px',
      },
    }
    flexPropsCopy.flexDirection = 'row'
  } else {
    flexPropsCopy.flexDirection = {
      xs: 'column',
      md: 'row',
    }
    contentProps = {
      ...contentProps,
      ml: {
        xs: '0px',
      },
      mt: {
        xs: 0,
        md: '0px',
      },
    }
  }
  if (noPaddingTop) {
    wrapperProps.pt = { xs: '0px' }
  }
  if (isButton) {
    labProps.display = { xs: 'none', md: 'block' }
    // wrapperProps.mt.xs = '0px'
    // wrapperProps.className = 'full-width-btn'
    contentProps.alignSelf = 'flex-start'
  }
  if (isTerms) {
    labProps.lineHeight = '3.2rem'
  }

  return (
    <Wrapper borderTop={borderTop} isCollapsed={isCollapsed} {...wrapperProps}>
      <Flex fullWidth {...flexPropsCopy}>
        <Box className="label" {...labProps}>
          {label}
        </Box>
        <Content {...contentProps}>{children}</Content>
      </Flex>
    </Wrapper>
  )
}

export default DataRow
