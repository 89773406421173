// npm
import React from 'react'
import { Skeleton } from '@mui/material'
// core
import MandateCard from '_core/components/molecules/cards/mandate/MandateCard'
import Pagination from '_core/components/molecules/pagination/Pagination'
import Panel from '_core/components/molecules/panels/Panel'
import PanelFooter from '_core/components/molecules/panels/PanelFooter'
import MasonryGrid from '_core/components/organisms/masonry/MasonryGrid'
import { dynamicMandatesQuery } from '_core/constants/dynamic-data-queries'
import useDynamicData from '_core/helpers/useDynamicData'
//account
import AppButton from 'account/components/atoms/buttons/AppButton'
import InterestsGuidance from 'account/components/molecules/interests/InterestsGuidance'
import ActionRequired from 'account/components/molecules/messaging/ActionRequired'
import Layout from 'account/components/organisms/global/Layout'
import { useAccount } from 'account/context/AccountContext'
import SectionHeading from 'public/components/molecules/sections/SectionHeading'

interface Props {
  data: Queries.DatoCmsAppAccountPage
  stateType?: 'favoriteMandate' | 'applications' | 'relevantMandates'
  mandateIds?: string[]
}

const MandateLanding = ({ data, stateType, mandateIds }: Props) => {
  const { state } = useAccount()

  let mandateArray: string[] = []
  if (stateType && state[stateType]) {
    state[stateType]?.map((obj) => {
      mandateArray.push(obj.id)
    })
  }

  const { contentProps, paginationProps } = useDynamicData({
    perPage: 12,
    dynamicType: 'mandate',
    dataResultSelector: 'mandates',
    dynamicContentQuery: dynamicMandatesQuery,
    ids: mandateIds ? mandateIds : mandateArray,
  })

  return (
    <Layout
      slug={data.slug}
      metadata={{
        seoTags: data.seoMetaTags,
      }}
    >
      <SectionHeading
        heading={data.title}
        boxProps={{
          mt: 8,
        }}
      />

      {data.guidanceText && (
        <InterestsGuidance
          text={data.guidanceText}
          button={data.guidanceButton[0]}
        />
      )}

      {!contentProps.fetched ? (
        <MasonryGrid
          masonryLayout="narrow"
          className="mandate-landing-skeleton"
        >
          {Array.from(Array(12)).map((_, index: number) => {
            const heights = [
              300, 350, 320, 380, 300, 306, 280, 370, 333, 350, 300, 350,
            ]

            return (
              <Skeleton
                key={`skeleton-${index}`}
                variant="rectangular"
                height={heights[index]}
                animation="wave"
                sx={{ mb: 2 }}
              />
            )
          })}
        </MasonryGrid>
      ) : (
        <>
          {(stateType === 'relevantMandates' || // relevant mandates do not exist in account context
            (state[stateType] &&
              Array.isArray(state[stateType]) &&
              state[stateType].length > 0)) &&
          contentProps &&
          contentProps.items &&
          contentProps.items.length > 0 ? (
            <>
              <MasonryGrid masonryLayout="narrow" className="mandate-landing">
                {contentProps.items.map((item: any) => {
                  const mandate: Queries.DatoCmsPageMandate = item.node
                  return (
                    <MandateCard
                      key={`m-${mandate.id}`}
                      mandate={mandate}
                      isDynamic
                    />
                  )
                })}
              </MasonryGrid>
              <Pagination landingPathPrefix="mandates" {...paginationProps} />
            </>
          ) : (
            <Panel>
              <ActionRequired>{data.requiredMessage}</ActionRequired>
              {data && data.requiredButton && data.requiredButton[0] && (
                <PanelFooter py={{ xs: 2 }}>
                  <AppButton
                    button={data.requiredButton[0]}
                    variant="contained"
                    color="primary"
                    sizeVariant="xl"
                    sx={{ ml: 2 }}
                  />
                </PanelFooter>
              )}
            </Panel>
          )}
        </>
      )}
    </Layout>
  )
}

export default MandateLanding
