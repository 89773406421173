import React from 'react'
import { Box } from '@mui/material'

import AppButton from 'account/components/atoms/buttons/AppButton'

interface Props {
  text: string
  button: Queries.DatoCmsAppButton
}

const InterestsGuidance = ({ text, button }: Props) => {
  return (
    <>
      {text && (
        <Box textAlign="center" mb={4}>
          {text}
          {button && (
            <Box display={{ xs: 'block', lg: 'inline-block' }}>
              <AppButton
                button={button}
                variant="outlined"
                sizeVariant="lg"
                colorVariant="outline-dark"
                withStartIcon
                sx={{
                  ml: 1,
                  my: 2,
                }}
              />
            </Box>
          )}
        </Box>
      )}
    </>
  )
}

export default InterestsGuidance
