import React from 'react'
import { styled } from '@mui/material'
import { useStaticQuery, graphql } from 'gatsby'

import InternalLink from '_core/components/atoms/links/InternalLink'
import IconLabel from '_core/components/molecules/labels/IconLabel'

import PageIcons from 'account/components/atoms/icons/PageIcons'

interface LinkProps {
  strong?: true
}

interface Props extends LinkProps {
  currentPageSlug: string
}

const StyledUl = styled('ul')(({ theme }) => ({
  listStyle: 'none',
  li: {
    marginBottom: '3rem',
    ['&:last-child']: {
      marginBottom: 0,
    },
  },
}))

const ListItem = styled('li', {
  shouldForwardProp: (prop) => prop !== 'strong',
})<LinkProps>(({ strong, theme }) => ({
  color: 'white',
  textTransform: 'uppercase',
  letterSpacing: '0.1rem',
  fontSize: '1.2rem',
  fontWeight: '700',
  svg: {
    fontSize: '1.8rem',
  },
  ...(strong && {
    color: theme.palette.primary.main,
  }),
}))

const StyledLink = styled(InternalLink, {
  shouldForwardProp: (prop) => prop !== 'strong',
})<LinkProps>(({ strong, theme }) => ({
  color: theme.palette.textAccountSidebar,
  ...(strong && {
    color: 'white',
  }),
}))

const Nav = ({ currentPageSlug, strong }: Props) => {
  const data = useStaticQuery<Queries.AccountNavQuery>(graphql`
    query AccountNav {
      allDatoCmsAppAccountPage(sort: { fields: position }) {
        edges {
          node {
            originalId
            title
            slug
            model {
              apiKey
            }
          }
        }
      }
    }
  `)
  const nav = data.allDatoCmsAppAccountPage.edges

  return (
    <StyledUl>
      {nav.map((item: Queries.DatoCmsAppAccountPage) => {
        const iconLabel = (
          <IconLabel
            icon={<PageIcons slug={item.node.slug} />}
            label={item.node.title}
          />
        )
        return (
          <ListItem key={`nav-${item.node.originalId}`} strong={strong}>
            {item.node.slug === currentPageSlug ? (
              <>{iconLabel}</>
            ) : (
              <StyledLink
                slug={item.node.slug}
                apiKey={item.node.model.apiKey}
                strong={strong}
              >
                {iconLabel}
              </StyledLink>
            )}
          </ListItem>
        )
      })}
    </StyledUl>
  )
}

export default Nav
