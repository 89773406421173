import React, { memo } from 'react'
import { Field, useFormikContext } from 'formik'

import CheckboxButton, { CheckboxButtonProps } from '../CheckboxButton'

interface Props
  extends Omit<CheckboxButtonProps, 'handleChange' | 'handleClick'> {
  value: string
}

const FormikCheckboxButton = ({ name, value, ...props }: Props) => {
  const { setFieldValue, values, handleChange } = useFormikContext()
  const selectedValues: string[] = values[name as keyof typeof values]

  return (
    <Field name={value}>
      {() => {
        const handleClick = () => {
          if (
            values &&
            values !== null &&
            selectedValues &&
            selectedValues !== null
          ) {
            if (selectedValues.includes(value)) {
              // Remove item from existing array
              const nextValues = selectedValues.filter((item) => item !== value)
              setFieldValue(name, nextValues)
            } else {
              // Add item to existing array
              const nextValues = [...selectedValues, value]
              setFieldValue(name, nextValues)
            }
          } else {
            // Create new array with selected item
            setFieldValue(name, [value])
          }
        }

        return (
          <CheckboxButton
            name={name}
            value={value}
            handleChange={handleChange}
            handleClick={handleClick}
            checked={values && selectedValues && selectedValues.includes(value)}
            {...props}
          />
        )
      }}
    </Field>
  )
}

export default memo(FormikCheckboxButton)
