import React from 'react'
import { Box, Divider, useMediaQuery, useTheme } from '@mui/material'
import { Formik, Form } from 'formik'
import { object } from 'yup'
import { Base64 } from 'js-base64'

import SaveButton from '_core/components/atoms/buttons/SaveButton'

import { useAccount, awsStorageResult } from 'account/context/AccountContext'
import { language, languageValue, term } from 'account/context/accountReducer'
import { rowField } from 'account/components/molecules/editRow/EditRow'
import EditDrawer from 'account/components/molecules/editRow/drawer/EditDrawer'
import { initialValues, validation } from 'account/helpers/profileFields'
import { ScrollToFieldError } from '_core/components/atoms/inputs/formik/ScrollToFieldError'

type formValues = {
  yearsOfExperience?: number
  departments?: string[]
  regions?: string[]
  levels?: string[]
  locations?: string[]
  products?: string[]
  sectors?: string[]
  languages?: languageValue[]
  employer?: string
  jobTitle?: string
  linkedIn?: string
  mobileNumber?: string
  cv?: File
}

interface Props {
  fields: rowField[]
  heading: string
  drawerOpen?: boolean
  setDrawerOpen: Function
}

const EditProfileRowDrawer = ({
  fields,
  heading,
  drawerOpen,
  setDrawerOpen,
}: Props) => {
  // console.log('🚀 ~ file: EditProfileRowDrawer.tsx ~ line 44 ~ fields', fields)
  const { state: accountState, update, upload } = useAccount()
  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'))

  const handleSubmit = (values: formValues) => {
    let updates: any = {}
    let withCv = false
    setDrawerOpen(false)

    fields.map((field: rowField) => {
      let terms: term[] = []

      const value = values[field.fieldId as keyof typeof values]
      if (field.isCv) {
        withCv = true
      }
      if (field.isTerms && Array.isArray(value)) {
        value.map((item: any, index: number) => {
          if (typeof item === 'string') {
            terms.push(JSON.parse(Base64.decode(item)))
          }
        })
      }

      if (field.parent && field.updateWithParent) {
        if (!updates[field.parent as keyof typeof updates]) {
          updates[field.parent as keyof typeof updates] = {}
        }
        updates[field.parent as keyof typeof updates][field.fieldId] = value
      } else if (field.isCv) {
        if (value !== '') {
          updates[field.fieldId as keyof typeof updates] = value
        }
      } else {
        if (values.languages && values.languages.length > 0) {
          let languages: language[] = new Array()

          values.languages.map((item) => {
            languages.push({
              ...item,
              proficiency: JSON.parse(Base64.decode(item.proficiency)),
            })
          })
          updates[field.fieldId] = languages
        } else {
          updates[field.fieldId] = field.isTerms ? terms : value
        }
      }
    })

    if (withCv && values.cv !== '') {
      upload({
        file: values.cv,
        filename: values.cv.name,
        folder: 'documents',
      }).then((result: awsStorageResult) => {
        if (result.key) {
          const dataObj = {
            profile: {
              ...accountState.profile,
              cv: result.key,
              linkedIn: values.linkedIn,
            },
          }
          update({ type: 'profile', variables: dataObj })
        }
      })
    } else {
      const dataObj = {
        profile: {
          ...accountState.profile,
          ...updates,
        },
      }
      // update and set status message
      update({ type: 'profile', variables: dataObj })
    }
  }

  let initialValues: initialValues = {}
  let validationSchema: validation = {}

  fields.map((field: rowField) => {
    if (field.isTerms && Array.isArray(field.value) && field.value.length > 0) {
      let encodedArray: string[] = []
      field.value.map((item) => {
        encodedArray.push(Base64.encode(JSON.stringify(item)))
      })

      initialValues[field.fieldId as keyof typeof initialValues] = encodedArray
    } else if (field.isCv) {
      initialValues[field.fieldId] = ''
    } else if (field.isLanguages && Array.isArray(field.value)) {
      let encodedArray: languageValue[] = []
      field.value.map((item: any) => {
        const language: language = item
        encodedArray.push({
          ...language,
          proficiency: Base64.encode(JSON.stringify(language.proficiency)),
        })
      })
      initialValues[field.fieldId as keyof typeof initialValues] = encodedArray
    } else {
      if (field.parent && field.updateWithParent) {
        initialValues[field.fieldId as keyof typeof initialValues] =
          // @ts-ignore
          accountState.profile[
            field.parent as keyof typeof accountState.profile
          ][field.fieldId]
      } else {
        initialValues[field.fieldId as keyof typeof initialValues] = field.value
      }
    }
    if (!field.isCv) {
      validationSchema[field.fieldId as keyof typeof validationSchema] =
        field.validation
    }
  })

  return (
    <EditDrawer
      heading={heading}
      setDrawerOpen={setDrawerOpen}
      drawerOpen={drawerOpen}
      onClose={() => setDrawerOpen(false)}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={object().shape(validationSchema)}
        onSubmit={(values) => {
          handleSubmit(values)
        }}
      >
        <Form>
          <ScrollToFieldError />
          {fields.map((field, index) => {
            return (
              <div key={`edit-field-${field.fieldId}`}>
                {index > 0 ? (
                  <Box mt={{ xs: 3 }} fontSize="1.6rem">
                    {field.drawerComponent}
                  </Box>
                ) : (
                  <>{field.drawerComponent}</>
                )}
              </div>
            )
          })}

          <Divider sx={{ mt: 4 }} />
          <SaveButton fullWidth={smUp ? false : true} />
        </Form>
      </Formik>
    </EditDrawer>
  )
}

export default EditProfileRowDrawer
