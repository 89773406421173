import React, { memo } from 'react'
import { Field, FieldProps, useFormikContext } from 'formik'

import Checkbox, { CheckboxProps } from '../Checkbox'
import ErrorMessage from '../ErrorMessage'

interface Props extends Omit<CheckboxProps, 'value' | 'label'> {
  name: string
  hideError?: boolean
  singleCheckbox?: boolean
  label?: string | React.ReactNode
}

const FormikCheckbox = ({
  name,
  hideError,
  singleCheckbox,
  checkboxProps,
  ...props
}: Props) => {
  const { setFieldValue } = useFormikContext()
  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => {
        const hasError = meta.touched === true && meta.error !== undefined
        return (
          <>
            {!hideError && (
              <ErrorMessage collapsed={hasError} mb={1}>
                {meta.error}
              </ErrorMessage>
            )}
            <Checkbox
              checked={field.value}
              inputProps={{ ...field }}
              checkboxProps={checkboxProps}
              error={hasError}
              singleCheckboxClickHandler={
                singleCheckbox ? setFieldValue : undefined
              }
              fieldName={singleCheckbox ? name : undefined}
              {...props}
            />
          </>
        )
      }}
    </Field>
  )
}

export default memo(FormikCheckbox)
