import React from 'react'
import { Box, Grid, styled, useTheme, useMediaQuery } from '@mui/material'

import StatBox, { statBoxType } from './StatBox'
import Card from '_core/components/atoms/cards/Card'

type statBox = {
  type: statBoxType
  label: string
}

const WrapperCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  ['.stat-grid-item']: {
    ['&:not(:last-child)']: {
      borderBottom: `1px solid ${theme.palette.outlineDark}`,
    },
    [theme.breakpoints.up('md')]: {
      ['&:nth-child(odd)']: {
        borderRight: `1px solid ${theme.palette.outlineDark}`,
      },
    },
  },
}))

const Stats = () => {
  const theme = useTheme()
  const xlUp = useMediaQuery(theme.breakpoints.up('xl'))
  const statBoxes: statBox[] = [
    {
      type: 'relevant',
      label: 'Relevant Mandates',
    },
    {
      type: 'favorites',
      label: 'Favourite Mandates',
    },
    {
      type: 'applications',
      label: 'Mandates Applied For',
    },
    {
      type: 'marketUpdates',
      label: 'Market Update Votes',
    },
  ]

  return (
    <>
      {!xlUp ? (
        <WrapperCard disableHover sx={{ mt: { xs: 0, lg: 2 } }}>
          <Grid container spacing={0}>
            {statBoxes.map((statBox: statBox) => (
              <Grid item xs={12} md={6} key={`stat-${statBox.type}`} className="stat-grid-item">
                <StatBox type={statBox.type} label={statBox.label} />
              </Grid>
            ))}
          </Grid>
        </WrapperCard>
      ) : (
        <Box my={2}>
          <Grid container spacing={1}>
            {statBoxes.map((statBox: statBox) => {
              return (
                <Grid item xs={6} key={`stat-xl-${statBox.type}`}>
                  <Card disableHover>
                    <StatBox type={statBox.type} label={statBox.label} />
                  </Card>
                </Grid>
              )
            })}
          </Grid>
        </Box>
      )}
    </>
  )
}

export default Stats
