import React from 'react'
import { Box, Grid, styled } from '@mui/material'

const StyledGrid = styled(Grid)(({ theme }) => ({
  width: '100%',
  margin: 0,
}))

const GridCol = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('xl')]: {
    borderRight: `1px solid ${theme.palette.outlineDark}`,
  },
}))

interface Props {
  leftCol: React.ReactNode
  rightCol: React.ReactNode
}

const TwoColPanelLayout = ({ leftCol, rightCol }: Props) => {
  return (
    <Box py={{ xl: 4 }}>
      <StyledGrid container spacing={0}>
        <GridCol item xs={12} xl={6}>
          {leftCol}
        </GridCol>
        <GridCol item xs={12} xl={6}>
          {rightCol}
        </GridCol>
      </StyledGrid>
    </Box>
  )
}

export default TwoColPanelLayout
