import React, { memo } from 'react'
import { styled } from '@mui/material'

import FormikTextField from '_core/components/atoms/inputs/formik/FormikTextField'

type fieldProps = {
  name: string
  label: string
  placeholder: string
}

interface Props {
  employerProps: fieldProps
  jobTitleProps: fieldProps
}

const StyledFormikTextField = styled(FormikTextField)(({ theme }) => ({
  marginBottom: 0,
}))

const Employer = ({ employerProps, jobTitleProps }: Props) => {
  return (
    <>
      <StyledFormikTextField {...employerProps} grey fullWidth />
      <StyledFormikTextField {...jobTitleProps} grey fullWidth />
    </>
  )
}

export default memo(Employer)
