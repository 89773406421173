import React, { memo } from 'react'
import { Box, BoxProps, styled } from '@mui/material'
import PhoneInput, { IPhoneInputProps } from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import { search } from '_core/constants/svg'

export interface PhoneNumberProps extends IPhoneInputProps {
  wrapperProps?: BoxProps
}

const Wrapper = styled(Box)(({ theme }) => ({
  ['&.error']: {
    ['.react-tel-input']: {
      borderColor: theme.palette.error.main,
    },
  },
  ['.react-tel-input']: {
    fontFamily: 'inherit',
    fontSize: '1.6rem',
    backgroundColor: theme.palette.lightGrey,
    borderRadius: theme?.shape?.borderRadius,
    transition: theme.transitions.create(['border-color']),
    border: '1px solid white',
    ['&:focus-within']: {
      borderColor: theme.palette.outlineDark,
    },
    ['input[type="tel"]']: {
      width: '100%',
      border: 'none',
      height: '64px',
      backgroundColor: theme.palette.lightGrey,
      borderRadius: theme?.shape?.borderRadius,
      paddingLeft: '62px !important',
    },
    ['.flag-dropdown']: {
      width: '52px !important',
      background: 'none !important',
      border: 'none',
      borderRight: `1px solid ${theme.palette.outlineDark}`,
      borderRadius: `${theme?.shape?.borderRadius} 0 0 ${theme?.shape?.borderRadius} !important`,
      ['.selected-flag']: {
        border: 'none !important',
        background: 'none !important',
        padding: '0 0 0 15px',
      },
    },
    ['.country-list']: {
      ...theme.typography.smallText,
      fontWeight: theme.typography.fontWeightMedium,
      maxWidth: '450px',
      maxHeight: '300px',
      ['.search']: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: '10px',
        marginRight: '10px',
        ['&:before']: {
          content: '""',
          width: '20px',
          height: '20px',
          display: 'block',
          backgroundImage: `url("${search}")`,
          backgroundPosition: '50% 50%',
          backgroundSize: '20px',
          backgroundRepeat: 'no-repeat',
        },
        ['.search-box']: {
          flexGrow: 1,
          fontSize: '1.4rem',
          padding: '10px',
          color: theme.palette.text.primary,
          lineHeight: 1.3,
        },
      },
      ['.country']: {
        ['.dial-code']: {
          color: theme.palette.textLight,
          fontWeight: theme.typography.fontWeightRegular,
          ['&:before']: {
            content: '"("',
          },
          ['&:after']: {
            content: '")"',
          },
        },
      },
    },
  },
}))

const preferred = ['gb', 'us', 'ru', 'ch']

const PhoneNumber = ({ wrapperProps, ...props }: PhoneNumberProps) => {
  return (
    <Wrapper {...wrapperProps}>
      <PhoneInput
        disableSearchIcon
        preferredCountries={preferred}
        country={'gb'}
        enableSearch
        preserveOrder={['preferredCountries']}
        {...props}
      />
    </Wrapper>
  )
}

export default memo(PhoneNumber)
