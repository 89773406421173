import React from 'react'

import InternalButtonLink, {
  InternalButtonLinkProps,
} from '_core/components/atoms/buttons/InternalButtonLink'
import PageIcons from 'account/components/atoms/icons/PageIcons'

interface Props extends Omit<InternalButtonLinkProps, 'children' | 'slug'> {
  button: Queries.DatoCmsAppButton
  withStartIcon?: true
}

const AppButton = ({ button, withStartIcon, ...props }: Props) => {
  let buttonProps = undefined

  if (button?.linkToAccountPage) {
    buttonProps = {
      slug: button.accountPageLink.slug,
      apiKey: button.accountPageLink.model.apiKey,
    }
    if (withStartIcon && button.accountPageLink.slug) {
      buttonProps = {
        ...buttonProps,
        startIcon: <PageIcons slug={button.accountPageLink.slug} />,
      }
    }
  }

  if (button?.publicPageSlug) {
    buttonProps = {
      slug: button?.publicPageSlug,
    }
  }

  if (buttonProps && buttonProps.slug) {
    return (
      <InternalButtonLink {...buttonProps} {...props}>
        {button.label}
      </InternalButtonLink>
    )
  }
  return null
}

export default AppButton
